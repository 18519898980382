import React, { Component } from "react";
import { connect } from "react-redux";
import { showModalSignup, resetSignupForm } from "../../reducer/actions";
import { Button } from "@rmwc/button";
import { Ripple } from "@rmwc/ripple";
import { Elevation } from "@rmwc/elevation";

import "./MailingListLeader.scss";

const mapDispatchToProps = dispatch => {
  return {
    showModalSignup: () => {
      dispatch(showModalSignup());
    },
    resetSignupForm: () => {
      dispatch(resetSignupForm());
    }
  };
};

class MailingListLeader extends Component {
  render() {
    return (
      <section
        className={this.props.fullscreen ? `wsh-hero fullscreen` : `wsh-hero`}
      >
        <div id="wsh-hero-image">
          <Elevation Elevation z={20}>
            <Ripple accent>
              <div>
                <img
                  src="/images/2017-DR-PI-large.png"
                  alt=""
                  onClick={() => {
                    this.props.showModalSignup();
                  }}
                />
              </div>
            </Ripple>
          </Elevation>
        </div>
        <section id="wsh-hero-text">
          <h1>Your Free Art Is Waiting!</h1>
          <p className="fullscreen-only" style={{ marginBottom: `0.25em` }}>
            <blockquote>
              A fun and quirky adventure&mdash;an author to watch!
            </blockquote>
            <cite>
              #1 NY Times Bestseller Patricia Briggs (about the book, not the
              art)
            </cite>
          </p>
          <p style={{ marginBottom: `0.25em` }}>
            <b>Does saving the world count if you do it out of spite?</b>
          </p>
          <p style={{ marginBottom: `0.25em` }}>
            If you've read <strong>Dick Richards: Private Eye</strong>, you know
            the answer. If you haven't, perhaps you're lost. Or maybe you just
            like art. I don't judge.
          </p>
          <p>
            <Button
              label="GET THE ART"
              raised
              ripple
              theme={[`secondaryBg`]}
              onClick={() => {
                this.props.showModalSignup();
              }}
            />
          </p>
        </section>
      </section>
    );
  }
}

export default connect(null, mapDispatchToProps)(MailingListLeader);
