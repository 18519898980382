const situations = Object.freeze([
  {
    summary: `Supplication`,
    elements: `a persecutor, a suppliant, a power in authority whose decision is doubtful`,
    description: `The suppliant appeals to the power in authority for deliverance from the persecutor.`
  },
  {
    summary: `Deliverance`,
    elements: `an unfortunate, a threatener, a rescuer`,
    description: `The unfortunate has caused a conflict, and the threatener is to carry out justice, but the rescuer saves the unfortunate.`
  },
  {
    summary: `Crime pursued by vengeance`,
    elements: `a criminal, an avenger`,
    description: `The criminal commits a crime that will not see justice, so the avenger seeks justice by punishing the criminal.`
  },
  {
    summary: `Vengeance taken for kin upon kin`,
    elements: `guilty kinsman; an avenging Kinsman; remembrance of the Victim, a relative of both.`,
    description: `Two entities, the guilty and the avenging kinsmen, are put into conflict over wrongdoing to the victim, who is allied to both. `
  },
  {
    summary: `Pursuit`,
    elements: `punishment, a fugitive`,
    description: `The fugitive flees punishment for a misunderstood conflict.`
  },
  {
    summary: `Disaster`,
    elements: `a vanquished power, a victorious enemy or a messenger`,
    description: `The vanquished power falls from their place after being defeated by the victorious enemy or being informed of such a defeat by the messenger.`
  },
  {
    summary: `Falling prey to cruelty/misfortune`,
    elements: `an unfortunate, a master or a misfortune`,
    description: `The unfortunate suffers from misfortune and/or at the hands of the master.`
  },
  {
    summary: `Revolt`,
    elements: `a tyrant, a conspirator`,
    description: `The tyrant, a cruel power, is plotted against by the conspirator.`
  },
  {
    summary: `Daring enterprise`,
    elements: `a bold leader, an object, an adversary`,
    description: `The bold leader takes the object from the adversary by overpowering the adversary.`
  },
  {
    summary: `Abduction`,
    elements: `an abductor, the abducted, a guardian`,
    description: `The abductor takes the abducted from the guardian.`
  },
  {
    summary: `The enigma`,
    elements: `a problem, an interrogator, a seeker`,
    description: `The interrogator poses a problem to the seeker and gives a seeker better ability to reach the seeker's goals.`
  },
  {
    summary: `Obtaining`,
    elements: `(a solicitor and an adversary who is refusing) or (an arbitrator and opposing parties)`,
    description: `The solicitor is at odds with the adversary who refuses to give the solicitor an object in the possession of the adversary, or an arbitrator decides who gets the object desired by opposing parties (the solicitor and the adversary).`
  },
  {
    summary: `Enmity of kin`,
    elements: `a malevolent kinsman, a hated or a reciprocally-hating kinsman`,
    description: `The malevolent kinsman and the hated or a second malevolent kinsman conspire together.`
  },
  {
    summary: `Rivalry of kin`,
    elements: `the preferred kinsman, the rejected kinsman, the object of rivalry`,
    description: `The object of rivalry chooses the preferred kinsman over the rejected kinsman.`
  },
  {
    summary: `Murderous adultery`,
    elements: `two adulterers, a betrayed spouse`,
    description: `Two adulterers conspire to kill the betrayed spouse.`
  },
  {
    summary: `Madness`,
    elements: `a madman, a victim`,
    description: `The madman goes insane and wrongs the victim.`
  },
  {
    summary: `Fatal imprudence`,
    elements: `the imprudent, a victim or an object lost`,
    description: `The imprudent, by neglect or ignorance, loses the object lost or wrongs the victim.`
  },
  {
    summary: `Involuntary crimes of love`,
    elements: `a lover, a beloved, a revealer`,
    description: `The lover and the beloved have unknowingly broken a taboo through their romantic relationship, and the revealer reveals this to them.`
  },
  {
    summary: `Slaying of kin unrecognized`,
    elements: `the slayer, an unrecognized victim`,
    description: `The slayer kills the unrecognized victim.`
  },
  {
    summary: `Self-sacrifice for an ideal`,
    elements: `a hero, an ideal, a creditor or a person/thing sacrificed`,
    description: `The hero sacrifices the person or thing for their ideal, which is then taken by the creditor.`
  },
  {
    summary: `Self-sacrifice for kin`,
    elements: `a hero, an ideal, a creditor or a person/thing sacrificed`,
    description: `The hero sacrifices a person or thing for their kinsman, which is then taken by the creditor.`
  },
  {
    summary: `All sacrificed for passion`,
    elements: `a lover, an object of fatal passion, the person/thing sacrificed`,
    description: `A lover sacrifices a person or thing for the object of their passion, which is then lost forever.`
  },
  {
    summary: `Necessity of sacrificing loved ones`,
    elements: `a hero, a beloved victim, the necessity for the sacrifice`,
    description: `The hero wrongs the beloved victim because of the necessity for their sacrifice.`
  },
  {
    summary: `Rivalry of superior vs. inferior`,
    elements: `a superior rival, an inferior rival, the object of rivalry`,
    description: `A superior rival bests an inferior rival and wins the object of rivalry.`
  },
  {
    summary: `Adultery`,
    elements: `two adulterers, a deceived spouse`,
    description: `Two adulterers conspire against the deceived spouse.`
  },
  {
    summary: `Crimes of love`,
    elements: `a lover, the beloved`,
    description: `A lover and the beloved break a taboo by initiating a romantic relationship.`
  },
  {
    summary: `Discovery of the dishonour of a loved one`,
    elements: `a discoverer, the guilty one`,
    description: `The discoverer discovers the wrongdoing committed by the guilty one.`
  },
  {
    summary: `Obstacles to love`,
    elements: `two lovers, an obstacle`,
    description: `Two lovers face an obstacle together.`
  },
  {
    summary: `An enemy loved`,
    elements: `a lover, the beloved enemy, the hater`,
    description: `The allied lover and hater have diametrically opposed attitudes towards the beloved enemy.`
  },
  {
    summary: `Ambition`,
    elements: `an ambitious person; a thing coveted; an adversary`,
    description: `The ambitious person seeks the thing coveted and is opposed by the adversary.`
  },
  {
    summary: `Conflict with a god`,
    elements: `a mortal; an immortal`,
    description: `The mortal and the imortal enter a conflict.`
  },
  {
    summary: `Mistaken jealousy`,
    elements: `a jealous one, an object of whose possession he is jealous, a supposed accomplice, a cause or an author of the mistake`,
    description: `The jealous one falls victim to the cause or the author of the mistake and becomes jealous of the object and becomes conflicted with the supposed accomplice.`
  },
  {
    summary: `Erroneous judgement`,
    elements: `a mistaken one, a victim of the mistake, a cause or author of the mistake, the guilty one`,
    description: `The mistaken one falls victim to the cause or the author of the mistake and passes judgment against the victim of the mistake when it should be passed against the guilty one instead.`
  },
  {
    summary: `Remorse`,
    elements: `a culprit, a victim or the sin, an interrogator`,
    description: `The culprit wrongs the victim or commits the sin, and is at odds with the interrogator who seeks to understand the situation.`
  },
  {
    summary: `Recovery of a lost one`,
    elements: `a seeker, the one found`,
    description: `The seeker finds the one found.`
  },
  {
    summary: `Loss of loved ones`,
    elements: `a kinsman slain, a kinsman spectator, an executioner`,
    description: `The killing of the kinsman slain by the executioner is witnessed by the kinsman.`
  }
]);

function format({ summary, elements, description }) {
  return `<dl><dt>${summary}</dt><dd class="elements">${elements}</dd><dd>${description}</dd></dl>`;
}

function random() {
  return Object.assign({}, situations[(Math.random() * 35 + 1) >> 0]);
}

export default { situations, format, random };
