import React, { Component, Fragment } from "react";
import MailingListLeader from "../MailingListLeader/MailingListLeader";
import Blurb from "../Blurb/Blurb";
import LandingDescription from "../LandingDescription/LandingDescription";
import LandingCatSpacer from "../LandingCatSpacer/LandingCatSpacer";

export default class Landing extends Component {
  render() {
    return (
      <Fragment>
        <MailingListLeader />
        <Blurb
          quote="A fun and quirky adventure&mdash;an author to watch!"
          img="/images/smallduck.png"
          blurber="Patricia Briggs, #1 NY Times Bestselling Author of the Mercy Thompson series"
        />
        <LandingDescription />
        <LandingCatSpacer />
      </Fragment>
    );
  }
}
