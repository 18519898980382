import "./Article.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import _ from "lodash";
import { sanitizeHTML } from "../../utils/utils";

class Article extends Component {
  componentDidMount() {
    // IE doesn't support Array.from(), so wrapping in try/catch
    try {
      // Edge doesn't like forEach on NodeList, so using Array.from
      Array.from(document.querySelectorAll(`#wsh-Article a`)).forEach(
        anchor => {
          // for local links, replace the node with a clean copy - no listeners (also nukes child listeners)
          if (
            anchor.href.includes(`chriswsh.com`) ||
            anchor.href.includes(`localhost`)
          ) {
            const anchorClone = anchor.cloneNode(true);
            anchor.parentNode.replaceChild(anchorClone, anchor);
            // add event listener - again, hard-coding isn't ideal, but is encapsulated in this component
            anchorClone.addEventListener(
              `click`,
              this.clickInterceptor.bind(this)
            );
          }
        }
      );
    } catch (e) {
      const anchors = document.querySelectorAll(`#wsh-Article a`);

      for (let i = 0; i < anchors.length; i++) {
        let anchor = anchors.item(i);

        if (
          anchor.href.includes(`chriswsh.com`) ||
          anchor.href.includes(`localhost`)
        ) {
          const anchorClone = anchor.cloneNode(true);
          anchor.parentNode.replaceChild(anchorClone, anchor);
          // add event listener - again, hard-coding isn't ideal, but is encapsulated in this component
          anchorClone.addEventListener(
            `click`,
            this.clickInterceptor.bind(this)
          );
        }
      }
    }
  }

  componentDidUpdate() {
    // IE doesn't support Array.from(), so wrapping in try/catch
    try {
      // Edge doesn't like forEach on NodeList, so using Array.from
      Array.from(document.querySelectorAll(`#wsh-Article a`)).forEach(
        anchor => {
          // for local links, replace the node with a clean copy - no listeners (also nukes child listeners)
          if (
            anchor.href.includes(`chriswsh.com`) ||
            anchor.href.includes(`localhost`)
          ) {
            const anchorClone = anchor.cloneNode(true);
            anchor.parentNode.replaceChild(anchorClone, anchor);
            // add event listener - again, hard-coding isn't ideal, but is encapsulated in this component
            anchorClone.addEventListener(
              `click`,
              this.clickInterceptor.bind(this)
            );
          }
        }
      );
    } catch (e) {
      const anchors = document.querySelectorAll(`#wsh-Article a`);

      for (let i = 0; i < anchors.length; i++) {
        let anchor = anchors.item(i);

        if (
          anchor.href.includes(`chriswsh.com`) ||
          anchor.href.includes(`localhost`)
        ) {
          const anchorClone = anchor.cloneNode(true);
          anchor.parentNode.replaceChild(anchorClone, anchor);
          // add event listener - again, hard-coding isn't ideal, but is encapsulated in this component
          anchorClone.addEventListener(
            `click`,
            this.clickInterceptor.bind(this)
          );
        }
      }
    }
  }

  clickInterceptor(event) {
    let { target } = event;
    while (`A` !== target.tagName) {
      target = target.parentNode;
    }

    event.preventDefault();
    const newRoute = `/${target.href
      .split(`/`)
      .slice(3)
      .join(`/`)}`;

    this.props.history.push(newRoute); // change route
  }

  renderArticle(article) {
    if (!article) return <section>Loading...</section>;

    return (
      <section>
        <h2>{article.title}</h2>
        <section dangerouslySetInnerHTML={sanitizeHTML(article.article)} />
      </section>
    );
  }

  render() {
    if (_.isEmpty(this.props.catalog[Symbol.for(`fullArticleInfo`)])) {
      console.log(`article not loaded yet`);
      return (
        <div id="wsh-Article">
          <div className="wsh-ArticleSkeletonHeader" />
          <div>
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
            <section className="wsh-ArticleSkeletonLine" />
          </div>
        </div>
      );
    }
    return (
      <div id="wsh-Article">
        {this.renderArticle(
          this.props.catalog[Symbol.for(`fullArticleInfo`)][
            this.props.match.params.title
          ]
        )}
      </div>
    );
  }
}

Article.propTypes = {
  catalog: PropTypes.object.isRequired
};

Article.defaultProps = {
  catalog: {}
};

export default withRouter(Article);
