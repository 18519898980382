import "./BookCatalogContainer.scss";

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { sanitizeHTML } from "../../utils/utils";
import { Elevation } from "@rmwc/elevation";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton
} from "@rmwc/dialog";

import Filligree from "../Filligree/Filligree";
import BookCatalogSkeleton from "../BookCatalogSkeleton/BookCatalogSkeleton";

import {
  setCurrentDisplayBook,
  showBookPreview,
  hideBookPreview
} from "../../reducer/actions";

const mapStateToProps = state => {
  const url = state.stories[Symbol.for(`bookCurrentDisplay`)];
  const book = url
    ? state.stories[Symbol.for(`fullBookInfo`)][
        state.stories[Symbol.for(`bookCurrentDisplay`)]
      ]
    : {};

  return {
    catalog: state.stories,
    displayed: state.stories[Symbol.for(`bookCurrentDisplay`)],
    showPreview: state.stories[Symbol.for(`showPreview`)],
    imagesPreloaded: state.stories[Symbol.for(`catalogImagesPreloaded`)],
    failedPreload: state.system[Symbol.for(`failedImagePreload`)],
    book,
    introAnimDone: state.system[Symbol.for(`introAnimDone`)]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentDisplayBook: url => {
      dispatch(setCurrentDisplayBook(url));
    },
    showBookPreview: () => {
      dispatch(showBookPreview());
    },
    hideBookPreview: () => {
      dispatch(hideBookPreview());
    }
  };
};

const headerMap = Object.freeze({
  novel: `Novels`,
  short: `In Anthologies...`,
  singlet: `Short Stories`,
  zpoetry: `Poetry`
});

class BookCatalogContainer extends Component {
  handleModalClose() {
    this.props.hideBookPreview();
    this.props.history.push(`/stories`);
    // this.props.setCurrentDisplayBook(``);
  }

  displayFlexCatalog(catalog) {
    // catalog is state.stories --> check reducers.js for info on data structure
    const categories = [];
    let i = 0;

    const flexCatalog = [];

    for (categories[i++] in catalog);
    categories.sort();

    // swap short and singlets manually -- kinda hacky
    let a = categories.indexOf(`short`);
    let b = categories.indexOf(`singlet`);
    if (a > -1 && b > -1) {
      [categories[a], categories[b]] = [categories[b], categories[a]];
    }

    categories.forEach((category, index) => {
      flexCatalog.push(
        <Elevation z={10} wrap>
          <h3 key={`category${index}`}>
            <Filligree addClass="wsh-u-hideMedDown" />
            {headerMap[category]}
            <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
          </h3>
        </Elevation>
      ); // this gets headers

      flexCatalog.push(
        <div className="wsh-flexCatalog" key={`flex${index}`}>
          {this.formatFlexCatalogBooks(catalog[category])}
        </div>
      );
    });

    return flexCatalog;
  }

  formatFlexCatalogBooks(books) {
    const formattedBooks = [];

    books.forEach(book => {
      formattedBooks.push(
        <section className="wsh-flexCatalogEntry" key={`${book.reactUrl}`}>
          <Link
            to={`/stories/${book.reactUrl}`}
            onClick={() => {
              this.props.setCurrentDisplayBook(book.reactUrl);
              this.props.showBookPreview();
            }}
          >
            {this.props.failedPreload.includes(book.imageUrl) ? (
              <svg width="150" height="225">
                <rect width="150" height="225" style={{ fill: `rgb(0,0,0)` }} />
              </svg>
            ) : (
              <div className="imgContainer">
                <img src={book.imageUrl} alt={book.title} />
              </div>
            )}
          </Link>

          <p>
            <Link
              to={`/stories/${book.reactUrl}`}
              onClick={() => {
                this.props.setCurrentDisplayBook(book.reactUrl);
                this.props.showBookPreview();
              }}
            >
              {book.title}
            </Link>
          </p>
        </section>
      );
    });
    return formattedBooks;
  }

  renderLinkBar(book) {
    return (
      <section className="buyLinks">
        {book.amazonPurchaseUrl ? (
          <a href={book.amazonPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/amazon.png"
              alt="Get it at Amazon"
            />
          </a>
        ) : null}
        {book.itunesPurchaseUrl ? (
          <a href={book.itunesPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/iTunes Store Badge.svg"
              alt="Get it at iTunes Store"
              className="wsh-u-hideSmallDown"
            />
            <img
              src="https://www.chriswsh.com/images/buttons/itunes-small.png"
              alt="Get it at iTunes Store"
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
        {book.bnPurchaseUrl ? (
          <a href={book.bnPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/bn.png"
              alt="Get it at Barnes &amp; Noble"
            />
          </a>
        ) : null}
        {book.koboPurchaseUrl ? (
          <a href={book.koboPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/kobo.png"
              alt="Get it at Kobo"
              className="wsh-u-hideSmallDown"
            />
            <img
              src="https://www.chriswsh.com/images/buttons/kobo-small.png"
              alt="Get it at Kobo"
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
        {book.scribdPurchaseUrl ? (
          <a href={book.scribdPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/Scribd_new_logo.png"
              alt="Get it at Scribd"
              className="wsh-u-hideSmallDown"
            />
            <img
              src="https://www.chriswsh.com/images/buttons/scribd-small.png"
              alt="Get it at Scribd"
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
        {book.playsterPurchaseUrl ? (
          <a href={book.playsterPurchaseUrl} className="imgLink">
            <img
              src="https://www.chriswsh.com/images/buttons/Playster Logo.png"
              alt="Get it at Playster"
              className="wsh-u-hideSmallDown"
            />
            <img
              src="https://www.chriswsh.com/images/buttons/playster-small.png"
              alt="Get it at Playster"
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
        {book.smashwordsPurchaseUrl ? (
          <a href={book.smashwordsPurchaseUrl} className="imgLink">
            <img
              src={`https://www.chriswsh.com/images/buttons/smashwords.png`}
              alt={`Get it at Smashwords`}
              className="wsh-u-hideSmallDown"
            />{" "}
            <img
              src={`https://www.chriswsh.com/images/buttons/smashwords-small.png`}
              alt={`Get it at Smashwords`}
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
        {book.otherPurchaseUrl ? (
          <a href={book.otherPurchaseUrl} className="imgLink">
            <img
              src={`${book.otherPurchaseLinkButtonUrl}`}
              alt={`Get it at ${book.otherPurchaseName}`}
              className="wsh-u-hideSmallDown"
            />
            <img
              src={`${book.otherPurchaseLinkButtonUrlSmall}`}
              alt={`Get it at ${book.otherPurchaseName}`}
              className="wsh-u-hideMedUp"
            />
          </a>
        ) : null}
      </section>
    );
  }

  renderTextBuyLinks(book) {
    return (
      <p className="textLinks">
        {book.amazonPurchaseUrl ? (
          <a href={book.amazonPurchaseUrl}>Amazon</a>
        ) : null}
        {book.bnPurchaseUrl ? (
          <a href={book.bnPurchaseUrl}>{`Barnes & Noble`}</a>
        ) : null}
        {book.itunesPurchaseUrl ? (
          <a href={book.itunesPurchaseUrl}>iTunes</a>
        ) : null}
        {book.koboPurchaseUrl ? <a href={book.koboPurchaseUrl}>Kobo</a> : null}
        {book.scribdPurchaseUrl ? (
          <a href={book.scribdPurchaseUrl}>Scribd</a>
        ) : null}
        {book.playsterPurchaseUrl ? (
          <a href={book.playsterPurchaseUrl}>Playster</a>
        ) : null}
        {book.smashwordsPurchaseUrl ? (
          <a href={book.smashwordsPurchaseUrl}>Smashwords</a>
        ) : null}
        {book.otherPurchaseUrl ? (
          <a href={book.otherPurchaseUrl}>{`${book.otherPurchaseName}`}</a>
        ) : null}
      </p>
    );
  }

  renderContent(book) {
    if (_.isEmpty(book)) {
      return (
        <section className="wrapper" style={{ height: `auto` }}>
          <p>Loading...</p>
        </section>
      );
    }

    return (
      <section className="wrapper">
        <section className="floatCover wsh-u-hideTinyDown">
          {this.props.failLoad ? (
            <svg width="150" height="225">
              <rect width="150" height="225" style={{ fill: `rgb(0,0,0)` }} />
            </svg>
          ) : (
            <img src={book.imageUrl} alt={book.title} />
          )}
        </section>

        <section className="description">
          <h3 className="title">{book.title} </h3>
          {this.renderLinkBar(book)}
          {/* book.publisher ? (
            <p className="publisher">
              <a href={book.publisherUrl}>{book.publisher}</a>
            </p>
          ) : null */}

          <section className="inlineCover wsh-u-hideSmallUp">
            {this.props.failLoad ? (
              <svg width="150" height="225">
                <rect width="150" height="225" style={{ fill: `rgb(0,0,0)` }} />
              </svg>
            ) : (
              <img src={book.imageUrl} alt={book.title} />
            )}
          </section>

          {<section dangerouslySetInnerHTML={sanitizeHTML(book.body)} />}
        </section>
      </section>
    );
  }

  componentDidMount() {
    const reactUrl = this.props.location.pathname.split("/")[2];
    // set current display book if it isn't already (e.g. initial load from bookmarks)
    if (reactUrl) {
      this.props.setCurrentDisplayBook(reactUrl);
      this.props.showBookPreview();
    }
  }

  componentDidUpdate() {
    /* catch browser navigation, forward and back, to correctly show preview */
    const reactUrl = this.props.location.pathname.split("/")[2];

    if (!reactUrl && this.props.showPreview) {
      this.props.hideBookPreview();
    }

    if (reactUrl && !this.props.showPreview) {
      this.props.setCurrentDisplayBook(reactUrl);
      this.props.showBookPreview();
    }
  }

  render() {
    const prefetchBlock = (
      <Fragment>
        <link rel="prefetch" href="/images/buttons/amazon.png" />
        <link rel="prefetch" href="/images/buttons/bn.jpg" />
        <link rel="prefetch" href="/data/sites/default/files/DMP-Button.png" />
        <link rel="prefetch" href="/images/buttons/iTunes Store Badge.svg" />
        <link rel="prefetch" href="/images/buttons/itunes-small.png" />
        <link rel="prefetch" href="/images/buttons/kobo.png" />
        <link rel="prefetch" href="/images/buttons/kobo-small.png" />
        <link rel="prefetch" href="/images/buttons/Scribd_new_logo.png" />
        <link rel="prefetch" href="/images/buttons/scribd-small.png" />
        <link rel="prefetch" href="/images/buttons/Playster Logo.png" />
        <link rel="prefetch" href="/images/buttons/playster-small.png" />
        <link rel="prefetch" href="/images/buttons/smashwords.png" />
        <link rel="prefetch" href="/images/buttons/smashwords-small.png" />
      </Fragment>
    );

    if (_.isEmpty(this.props.catalog))
      return (
        <div id="wsh-stories">
          {prefetchBlock}
          <BookCatalogSkeleton />
        </div>
      );

    return (
      <section id="wsh-stories">
        {prefetchBlock}
        <Fragment>{this.displayFlexCatalog(this.props.catalog)}</Fragment>
        <Dialog
          open={this.props.showPreview}
          onClose={() => {
            document
              .getElementById(`book-preview-scroll-target`)
              .scrollTo(0, 0);
            this.handleModalClose();
          }}
          className="wsh-bookPreviewModal"
        >
          <DialogContent id="book-preview-scroll-target">
            {this.renderContent(this.props.book)}
            <DialogActions>
              {this.renderTextBuyLinks(this.props.book)}
              <DialogButton
                label="BACK!"
                raised
                ripple
                theme={["secondaryBg", "onSecondary"]}
                onClick={() => {
                  this.handleModalClose();
                }}
              />
            </DialogActions>
          </DialogContent>
        </Dialog>
      </section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookCatalogContainer));
