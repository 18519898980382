import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (
        this.props.location.pathname.includes(`/stories`) &&
        prevProps.location.pathname.includes(`/stories`)
      ) {
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
