import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  sendEmailToMailChimp,
  hideModalSignup,
  resetSignupForm,
  setEmail,
  setGDPR
} from "../../reducer/actions";
import MailingListMessage from "../MailingListMessage/MailingListMessage";
import { Button } from "@rmwc/button";
import { TextField, TextFieldHelperText } from "@rmwc/textfield";
import { ThemeProvider } from "@rmwc/theme";
import { Checkbox } from "@rmwc/checkbox";
import { LinearProgress } from "@rmwc/linear-progress";

import "./MailingListForm.scss";

const mapStateToProps = state => {
  return {
    email: state.mailingList.email,
    GDPR: state.mailingList.GDPR,
    status: state.mailingList.status,
    mailchimpErrorMessage: state.mailingList.mailchimpErrorMessage,
    validationErrors: state.mailingList.validationErrors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitEmail: (email, GDPR) => {
      dispatch(sendEmailToMailChimp(email, GDPR));
    },
    resetSignupForm: () => {
      dispatch(resetSignupForm());
    },
    hideModalSignup: () => {
      dispatch(hideModalSignup());
    },
    setEmail: email => {
      dispatch(setEmail({ email }));
    },
    setGDPR: GDPR => {
      dispatch(setGDPR({ GDPR }));
    }
  };
};

const formTheme = {
  primary: `#630070`,
  onPrimary: ``,
  secondary: `#630070`,
  onSecondary: ``,
  background: ``,
  onBackground: ``,
  surface: ``,
  onSurface: ``
};

class MailingListForm extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired
  };

  render() {
    let Component = null;
    switch (this.props.status) {
      case `sending`:
        Component = connect(mapStateToProps)(ProcessingScreen);
        break;
      case `signupError`:
        Component = connect(
          mapStateToProps,
          mapDispatchToProps
        )(SignupErrorScreen);
        break;
      case `mailChimpError`:
        Component = connect(
          mapStateToProps,
          mapDispatchToProps
        )(MailChimpErrorScreen);
        break;
      case `success`:
        Component = connect(
          mapStateToProps,
          mapDispatchToProps
        )(SignupSuccessScreen);
        break;
      case `input`:
      default:
        Component = connect(mapStateToProps, mapDispatchToProps)(InputScreen);
        break;
    }
    return (
      <ThemeProvider id="wsh-SubscribeForm" options={formTheme}>
        <Component />
      </ThemeProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailingListForm);

/* === Views === */

class InputScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      GDPR: this.props.GDPR
    };
  }

  render() {
    const invalidEmail = _.has(this.props.validationErrors, `email`);
    return (
      <Fragment>
        <h3>Get The Art For Free!</h3>
        <section className="flex-wrapper">
          <section className="form-wrapper">
            <p>
              Enter your email below to join my newsletter and I'll send you the
              Dick Richards Charater Dossier Art.
            </p>
            <TextField
              key="email"
              label="E-mail Address"
              type="text"
              icon="email"
              fullwidth
              outlined
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
              invalid={invalidEmail}
              value={this.state.email}
            />
            <TextFieldHelperText persistent>
              {_.has(this.props.validationErrors, `email`) && (
                <MailingListMessage
                  status={`error`}
                  message={`Please enter a valid e-mail address`}
                />
              )}
            </TextFieldHelperText>

            <Checkbox
              label="I agree to receive marketing e-mail from WSH Creative, LLC"
              checked={this.state.GDPR}
              onChange={e => {
                this.setState({ GDPR: e.target.checked });
              }}
            />
            <TextFieldHelperText persistent>
              {_.has(this.props.validationErrors, `GDPR`) && (
                <MailingListMessage
                  status={`error`}
                  message={`Check the box above so I can send you stuff`}
                />
              )}
            </TextFieldHelperText>

            <p>
              <b>Marketing (Anti-Spam/GDPR) Info</b>
            </p>
            <p>
              You can unsubscribe at any time by clicking the link in the footer
              of my e-mails. I will not use your e-mail address for anything but
              this newsletter. I also promise not to drool while writing the
              e-mails.
            </p>
            <p>
              I use MailChimp as my marketing platform. By clicking below to
              subscribe, you acknowledge that your information will be
              transferred to MailChimp for processing. To learn more about
              MailChimp's privacy policy, click{" "}
              <a
                href="https://mailchimp.com/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <section className="button-bar">
              <Button
                label="Not Yet"
                onClick={() => {
                  this.props.hideModalSignup();
                  _.delay(this.props.resetSignupForm, 200);
                }}
              />
              <Button
                label="Sign Me Up!"
                raised
                ripple
                onClick={() => {
                  this.props.submitEmail(this.state.email, this.state.GDPR);
                }}
              />
            </section>
          </section>
        </section>
      </Fragment>
    );
  }
}

const ProcessingScreen = props => (
  <Fragment>
    <p>
      Your information is now adventuring through the interwebs, sustained by
      the joys of self-discovery and the power of friendship.
    </p>
    <p>
      Sassy unicorns and orphan cyborgs with bad attitudes may or may not be
      involved.
    </p>
    <p>
      <LinearProgress />
    </p>
  </Fragment>
);

const SignupErrorScreen = props => (
  <Fragment>
    <p>Yikes! Something happened, not sure what.</p>
    <p>
      If you believe in miracles, closing this pop-up and chanting eldritch
      hymns during the next solar eclipse might work.
    </p>
    <p>
      If that's too much effort,{" "}
      <a href="mailto:chris@chriswsh.com?subject=I Need My Free Book">
        send me an email
      </a>{" "}
      and I'll sign you up personally.
    </p>
    <section className="button-bar">
      <Button
        label="Close"
        raised
        ripple
        onClick={() => {
          props.hideModalSignup();
          _.delay(props.resetSignupForm, 200);
        }}
      />
    </section>
  </Fragment>
);

const MailChimpErrorScreen = props => (
  <Fragment>
    <p>Well, your information went through, but MailChimp barfed up this:</p>
    <p>{props.mailchimpErrorMessage}</p>
    <p>
      If that doesn't help,{" "}
      <a href="mailto:chris@chriswsh.com?subject=MailChimp Signup Issue">
        email me
      </a>{" "}
      and I'll take a look at it.
    </p>
    <section className="button-bar">
      <Button
        label="Close"
        raised
        ripple
        onClick={() => {
          props.hideModalSignup();
          _.delay(props.resetSignupForm, 200);
        }}
      />
    </section>
  </Fragment>
);

const SignupSuccessScreen = props => (
  <Fragment>
    <h3>Success!</h3>
    <section>
      <p>
        You have successfully signed up. There was a socially acceptable amount
        of rejoicing.
      </p>
      <p>
        I use a double opt-in process to prevent knaves from signing people up
        without their knowledge, so you'll be receiving an e-mail to confirm.
        (Check your spam folder if you can't find it.)
      </p>
      <p>
        After that, book book <strong>book</strong> book book book <em>book</em>
        . Free!
      </p>
      <section className="button-bar">
        <Button
          label="Woo Hoo!"
          raised
          ripple
          onClick={() => {
            props.hideModalSignup();
            _.delay(props.resetSignupForm, 200);
          }}
        />
      </section>
    </section>
  </Fragment>
);
