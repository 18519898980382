import React, { Component } from "react";
import { Dialog, DialogContent } from "@rmwc/dialog";
import { connect } from "react-redux";
import { hideModalSignup, setEmail, setGDPR } from "../../reducer/actions";
import MailingListForm from "../MailingListForm/MailingListForm";

const mapStateToProps = state => {
  return { showSignup: state.mailingList.showModal };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSignupForm: () => {
      dispatch(setEmail({}));
      dispatch(setGDPR({}));
    },
    hideModalSignup: () => {
      dispatch(hideModalSignup());
    }
  };
};

class ModalMailingList extends Component {
  render() {
    return (
      <Dialog
        open={this.props.showSignup}
        className="wsh-signupModal"
        preventOutsideDismiss={true}
      >
        <DialogContent>
          <MailingListForm />
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalMailingList);
