import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@rmwc/theme";

import { Ripple } from "@rmwc/ripple";

import "./Header.scss";

const headerTheme = {
  primary: `#162eae`, // primary used for primary ripples (header)
  onPrimary: ``,
  secondary: ``,
  onSecondary: ``,
  background: ``,
  onBackground: ``,
  surface: ``,
  onSurface: ``
};

export default class Header extends Component {
  render() {
    return (
      <ThemeProvider options={headerTheme}>
        <header id="wsh-Header">
          <nav id="main-nav">
            <Link to="/" role="link">
              <Ripple primary>
                <h1>Chris Wong Sick Hong</h1>
              </Ripple>
            </Link>
            <Ripple primary>
              <Link to="/stories" role="link" className="nav-link">
                <span>Stories</span>
              </Link>
            </Ripple>
            <Ripple primary>
              <Link to="/about" role="link" className="nav-link">
                <span>About</span>
              </Link>
            </Ripple>
          </nav>
        </header>
      </ThemeProvider>
    );
  }
}
