import { combineReducers } from "redux";
import { ACTIONS } from "./actions";

const initialState = {
  mailingList: {
    email: ``,
    GPDR: false,
    status: ``,
    validationErrors: {},
    mailchimpErrorMessage: ``,
    showModal: false
  },
  stories: {
    [Symbol.for(
      `catalogURL`
    )]: `https://www.chriswsh.com/data/api/books?_format=json`,
    [Symbol.for(`fullBookInfo`)]: {},
    [Symbol.for(`bookCurrentDisplay`)]: ``,
    [Symbol.for(`bookPreviewDisplay`)]: {},
    [Symbol.for(`bookPreviewActive`)]: false,
    [Symbol.for(`catalogImagesPreloaded`)]: false,
    [Symbol.for(`showPreview`)]: false
  },
  blog: {},
  articles: {
    [Symbol.for(
      `articleURL`
    )]: `https://www.chriswsh.com/data/api/articles?_format=json`,
    [Symbol.for(`fullArticleInfo`)]: {}
  },
  fetchList: {},
  system: {
    [Symbol.for(`failedImagePreload`)]: [],
    [Symbol.for(`successImagePreload`)]: [],
    [Symbol.for(`stickySupport`)]: false,
    [Symbol.for(`intoAnimDone`)]: false
  }
};

function mailingList(state = initialState.mailingList, action) {
  switch (action.type) {
    case ACTIONS.SET_EMAIL:
      return { ...state, email: action.email };
    case ACTIONS.SET_GDPR:
      return { ...state, GDPR: action.GDPR };
    case ACTIONS.SET_STATUS:
      return { ...state, status: action.status };
    case ACTIONS.SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: action.validationErrors };
    case ACTIONS.SET_MAILCHIMP_ERROR_MESSAGE:
      return { ...state, mailchimpErrorMessage: action.mailchimpErrorMessage };
    case ACTIONS.SHOW_MODAL_SIGNUP:
      return { ...state, showModal: true };
    case ACTIONS.HIDE_MODAL_SIGNUP:
      return { ...state, showModal: false };
    default:
      return state;
  }
}

function stories(state = initialState.stories, action) {
  switch (action.type) {
    case ACTIONS.ADD_BOOK_TO_CACHE:
      const { title, reactUrl, imageUrl, ...restOfBook } = action.book;
      return Object.assign(
        {},
        state,
        // store the title, reactUrl and imageUrl in the manifest to display the catalog
        {
          [action.book.type]: state[action.book.type]
            ? state[action.book.type].concat([{ title, imageUrl, reactUrl }])
            : [{ title, imageUrl, reactUrl }]
        },
        // store the rest of the book info by reactUrl key for later display
        {
          [Symbol.for(`fullBookInfo`)]: Object.assign(
            state[Symbol.for(`fullBookInfo`)],
            { [reactUrl]: { title, imageUrl, ...restOfBook } }
          )
        }
      );
    case ACTIONS.SET_STORY_CACHE:
      return Object.assign({}, state, action.cache);
    case ACTIONS.SET_PREVIEW_BOOK:
      return Object.assign({}, state, {
        [Symbol.for(`bookPreviewDisplay`)]: action.book
      });
    case ACTIONS.CLEAR_PREVIEW_BOOK:
      return Object.assign({}, state, {
        [Symbol.for(`bookPreviewDisplay`)]: {}
      });
    case ACTIONS.BOOK_PREVIEW_ACTIVE:
      return Object.assign({}, state, {
        [Symbol.for(`bookPreviewActive`)]: action.active
      });
    case ACTIONS.SET_CURRENT_DISPLAY_BOOK:
      return Object.assign({}, state, {
        [Symbol.for(`bookCurrentDisplay`)]: action.url
      });
    case ACTIONS.SET_CATALOG_IMAGE_PRELOAD_STATE:
      return Object.assign({}, state, {
        [Symbol.for(`catalogImagesPreloaded`)]: action.state
      });
    case ACTIONS.SHOW_PREVIEW: {
      return Object.assign({}, state, { [Symbol.for(`showPreview`)]: true });
    }
    case ACTIONS.HIDE_PREVIEW: {
      return Object.assign({}, state, { [Symbol.for(`showPreview`)]: false });
    }
    default:
      return state;
  }
}

function blog(state = initialState.blog, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function articles(state = initialState.articles, action) {
  switch (action.type) {
    case ACTIONS.ADD_ARTICLE_TO_CACHE:
      const {
        title,
        subgroup,
        reactUrl,
        externalUrl,
        ...restOfArticle
      } = action.article;
      return Object.assign(
        {},
        state,
        // store title and group in manifest for display
        {
          [subgroup]: state[subgroup]
            ? state[subgroup].concat([{ title, reactUrl, externalUrl }])
            : [{ title, reactUrl, externalUrl }]
        },
        // store the rest of the article info
        {
          [Symbol.for(`fullArticleInfo`)]: Object.assign(
            state[Symbol.for(`fullArticleInfo`)],
            { [reactUrl]: { title, ...restOfArticle } }
          )
        }
      );
    default:
      return state;
  }
}

function fetchList(state = initialState.fetchList, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function system(state = initialState.system, action) {
  switch (action.type) {
    case ACTIONS.SET_FAILED_IMAGE_PRELOAD:
      return Object.assign({}, state, {
        [Symbol.for(`failedImagePreload`)]: action.urls
      });
    case ACTIONS.ADD_FAILED_IMAGE_PRELOAD:
      return Object.assign({}, state, {
        [Symbol.for(`failedImagePreload`)]: state[
          Symbol.for(`failedImagePreload`)
        ].concat(action.urls)
      });
    case ACTIONS.SET_SUCCESS_IMAGE_PRELOAD:
      return Object.assign({}, state, {
        [Symbol.for(`successImagePreload`)]: action.imgs
      });
    case ACTIONS.ADD_SUCCESS_IMAGE_PRELOAD:
      return Object.assign({}, state, {
        [Symbol.for(`successImagePreload`)]: state[
          Symbol.for(`successImagePreload`)
        ].concat(action.imgs)
      });
    case ACTIONS.SET_STICKY_SUPPORT:
      return Object.assign({}, state, {
        [Symbol.for(`stickySupport`)]: action.sticky
      });
    case ACTIONS.SET_INTRO_ANIM_DONE:
      return Object.assign({}, state, {
        [Symbol.for(`introAnimDone`)]: true
      });
    default:
      return state;
  }
}

export default combineReducers({
  mailingList,
  stories,
  blog,
  articles,
  fetchList,
  system
});
