import React, { Fragment } from "react";
import { Elevation } from "@rmwc/elevation";
import Filligree from "../Filligree/Filligree";
import BookCatalogSkeletonEntry from "../BookCatalogSkeletonEntry/BookCatalogSkeletonEntry";

function skeletonEntries(num = 1) {
  return Array.from({ length: num }).reduce(
    (acc, cur, index) =>
      acc.concat(<BookCatalogSkeletonEntry key={`${index}`} />),
    []
  );
}

function BookCatalogContainer() {
  return (
    <Fragment>
      <Elevation z={10} wrap>
        <h3>
          <Filligree addClass="wsh-u-hideMedDown" />
          Novels
          <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
        </h3>
      </Elevation>
      <div className="wsh-flexCatalog">{skeletonEntries(2)}</div>
      <Elevation z={10} wrap>
        <h3>
          <Filligree addClass="wsh-u-hideMedDown" />
          Short Stories
          <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
        </h3>
      </Elevation>
      <div className="wsh-flexCatalog">{skeletonEntries(5)}</div>
      <Elevation z={10} wrap>
        <h3>
          <Filligree addClass="wsh-u-hideMedDown" />
          In Anthologies...
          <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
        </h3>
      </Elevation>
      <div className="wsh-flexCatalog">{skeletonEntries(6)}</div>
    </Fragment>
  );
}

export default BookCatalogContainer;
