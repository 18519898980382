import React from "react";
import PropTypes from "prop-types";

import { sanitizeHTML } from "../../utils/utils";

const MailingListMessage = props => (
  <span className={`status ${props.status}`}>
    <span dangerouslySetInnerHTML={sanitizeHTML(`${props.message}`)} />
  </span>
);

MailingListMessage.propTypes = {
  status: PropTypes.string.isRequired, // [`sending`, `error`, `success`]
  message: PropTypes.string.isRequired
};

export default MailingListMessage;
