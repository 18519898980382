import _ from "lodash";
import axios from "axios";
import isEmail from "validator/lib/isEmail";
import { parseBook, parseArticle, preloadImages } from "../utils/utils";

// set to simple request to disable preflight OPTIONS request
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// Action types
export const ACTIONS = Object.freeze({
  // state.mailingList reducer
  SET_EMAIL: Symbol(`Set Email`),
  SET_GDPR: Symbol(`Set GDPR`),
  SET_STATUS: Symbol(`Set Signup Workflow Status`),
  SET_VALIDATION_ERRORS: Symbol(`Set Validation Errors`),
  SEND_EMAIL_TO_MAILCHIMP: Symbol(`Send Email Address to MailChimp`),
  SET_MAILCHIMP_ERROR_MESSAGE: Symbol(
    `Set Error Message Received from MailChimp`
  ),
  SHOW_MODAL_SIGNUP: Symbol(`Show Modal Signup`),
  HIDE_MODAL_SIGNUP: Symbol(`Hide Modal Signup`),

  // state.stories reducer
  ADD_BOOK_TO_CACHE: Symbol(`Add Book to Cache`),
  SET_STORY_CACHE: Symbol(`Set Story Cache En Masse`),
  SORT_STORY_CACHE: Symbol(`Sort Story Cache`), // <-- Not linked to anything yet
  SET_PREVIEW_BOOK: Symbol(`Set Catalog Preview Book`),
  CLEAR_PREVIEW_BOOK: Symbol(`Clear Preview Book`),
  BOOK_PREVIEW_ACTIVE: Symbol(`Set Whether the Preview Is Active`),
  SET_CURRENT_DISPLAY_BOOK: Symbol(`Set the Currently Displayed Book`),
  SET_CATALOG_IMAGE_PRELOAD_STATE: Symbol(`Set Catalog Image Preload State`),
  SHOW_PREVIEW: Symbol(`Show Book Preview`),
  HIDE_PREVIEW: Symbol(`Hide Book Preview`),

  // state.articles reducer
  ADD_ARTICLE_TO_CACHE: Symbol(`Add Article to Cache`),
  SORT_ARTICLE_CACHE: Symbol(`Sort Article Cache`), // <-- Not linked to anything yet

  // state.system reducer
  SET_FAILED_IMAGE_PRELOAD: Symbol(
    `Set Array of Images Which Failed to Preload`
  ),
  ADD_FAILED_IMAGE_PRELOAD: Symbol(
    `Add Array of Images Which Failed to Preload`
  ),
  SET_SUCCESS_IMAGE_PRELOAD: Symbol(
    `Set Array of Images Which Successfully Preloaded`
  ),
  ADD_SUCCESS_IMAGE_PRELOAD: Symbol(
    `Add Array of Images Which Successfully Preloaded`
  ),
  SET_STICKY_SUPPORT: Symbol(`Set Flag for position: sticky Support`),
  SET_INTRO_ANIM_DONE: Symbol(`Set Flag For Intro Animation Being Done`)
});

// Action creators
// MailChimp
export function sendEmailToMailChimp(email, GDPR = false) {
  return dispatch => {
    dispatch(setEmail({ email }));
    dispatch(setGDPR({ GDPR }));

    let validationErrors = {};
    if (!isEmail(email)) _.set(validationErrors, `email`, `invalid email`);

    if (!GDPR) _.set(validationErrors, `GDPR`, `consent not given`);

    if (!_.isEmpty(validationErrors)) {
      dispatch(setValidationErrors(validationErrors));
      return;
    }

    dispatch(clearValidationErrors());

    dispatch(
      setStatus({
        status: `sending`
      })
    );

    const initial = _.now();
    const miminimProcessingDisplayTimeMs = 6000;

    axios
      .post(
        `https://www.chriswsh.com/php/GDPR.php`,
        `email=${encodeURIComponent(email)}&GDPR=true`
      )
      .catch(() => {})
      .then(() => {
        axios
          .post(
            `https://www.chriswsh.com/php/subscribe.php`,
            `email=${encodeURIComponent(email)}`
          )
          .then(response => {
            const elapsed = _.now() - initial;
            const status =
              `error` === response.data.result ? `mailChimpError` : `success`;

            _.delay(() => {
              if (`error` === status) dispatch(setStatus({ status }));
              dispatch(
                setMailChimpErrorMessage({
                  mailchimpErrorMessage: response.data.msg
                })
              );
              dispatch(setStatus({ status }));
            }, miminimProcessingDisplayTimeMs - elapsed);
          })
          .catch(error => {
            const elapsed = _.now() - initial;

            _.delay(
              dispatch,
              miminimProcessingDisplayTimeMs - elapsed,
              setStatus({
                status: `signupError`
              })
            );
          });
      });
  };
}

export function resetSignupForm() {
  return dispatch => {
    dispatch(setEmail({}));
    dispatch(setGDPR({}));
    dispatch(setStatus({}));
    dispatch(setValidationErrors());
  };
}

export function setEmail({ email = `` }) {
  return { type: ACTIONS.SET_EMAIL, email };
}

export function setGDPR({ GDPR = false }) {
  return { type: ACTIONS.SET_GDPR, GDPR };
}

export function setStatus({ status = `` }) {
  return { type: ACTIONS.SET_STATUS, status };
}

export function setValidationErrors(validationErrors = {}) {
  return { type: ACTIONS.SET_VALIDATION_ERRORS, validationErrors };
}

export function clearValidationErrors() {
  return { type: ACTIONS.SET_VALIDATION_ERRORS, validationErrors: {} };
}

export function setMailChimpErrorMessage({ mailchimpErrorMessage = `` }) {
  return { type: ACTIONS.SET_MAILCHIMP_ERROR_MESSAGE, mailchimpErrorMessage };
}

export function clearMailChimpErrorMessage() {
  return {
    type: ACTIONS.SET_MAILCHIMP_ERROR_MESSAGE,
    mailchimpErrorMessage: ``
  };
}

export function showModalSignup() {
  return {
    type: ACTIONS.SHOW_MODAL_SIGNUP
  };
}

export function hideModalSignup() {
  return {
    type: ACTIONS.HIDE_MODAL_SIGNUP
  };
}

/*export function loadBookCatalog(url) {
  return dispatch => {
    if (storageAvailable(`localStorage`) && localStorage.books) {
      JSON.parse(localStorage.books).reduce((acc, book) => {
        dispatch(addBookToCache(book));
      }, undefined);
    }

    loadBookCatalogFromDrupalAPI(url);
  };
}*/

// Getting Book Info
export function loadBookCatalogFromDrupalAPI(url) {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        const bookList = response.data.reduce((acc, book) => {
          const parsed = parseBook(book);
          // dispatch(addBookToCache(parsed));
          return acc.concat(parsed);
        }, []);

        const test = bookList.reduce(
          (acc, book) => {
            const { title, reactUrl, imageUrl, ...restOfBook } = book;
            return Object.assign(
              acc,
              {
                [book.type]: acc[book.type]
                  ? acc[book.type].concat([{ title, imageUrl, reactUrl }])
                  : [{ title, imageUrl, reactUrl }]
              },
              {
                [Symbol.for(`fullBookInfo`)]: Object.assign(
                  acc[Symbol.for(`fullBookInfo`)],
                  { [reactUrl]: { title, imageUrl, ...restOfBook } }
                )
              }
            );
          },
          {
            [Symbol.for(
              `catalogURL`
            )]: `https://www.chriswsh.com/data/api/books?_format=json`,
            [Symbol.for(`fullBookInfo`)]: {},
            [Symbol.for(`bookCurrentDisplay`)]: ``,
            [Symbol.for(`bookPreviewDisplay`)]: {},
            [Symbol.for(`bookPreviewActive`)]: false,
            [Symbol.for(`catalogImagesPreloaded`)]: false,
            [Symbol.for(`showPreview`)]: false
          }
        );

        dispatch(setStoryCache(test));

        /* if (storageAvailable(`localStorage`))
          localStorage.books = JSON.stringify(bookList); */
      })
      .catch(error => {
        // load locally
        // from local storage
      });
  };
}

export function addBookToCache(book) {
  return {
    type: ACTIONS.ADD_BOOK_TO_CACHE,
    book
  };
}

export function setStoryCache(cache) {
  return {
    type: ACTIONS.SET_STORY_CACHE,
    cache
  };
}

export function setPreviewBook(book) {
  return {
    type: ACTIONS.SET_PREVIEW_BOOK,
    book
  };
}

export function clearPreviewBook(book) {
  return {
    type: ACTIONS.CLEAR_PREVIEW_BOOK
  };
}

export function bookPreviewActive(active = false) {
  return {
    type: ACTIONS.BOOK_PREVIEW_ACTIVE,
    active
  };
}

export function setCurrentDisplayBook(url) {
  return {
    type: ACTIONS.SET_CURRENT_DISPLAY_BOOK,
    url
  };
}

export function setCatalogImagePreloadState(state) {
  return {
    type: ACTIONS.SET_CATALOG_IMAGE_PRELOAD_STATE,
    state
  };
}

export function showBookPreview() {
  return {
    type: ACTIONS.SHOW_PREVIEW
  };
}

export function hideBookPreview() {
  return {
    type: ACTIONS.HIDE_PREVIEW
  };
}

// Getting article info
export function loadArticleCatalogFromDrupalAPI(url) {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        // add articles to store

        response.data.map(article => {
          dispatch(addArticleToCache(parseArticle(article)));
          return null;
        });
      })
      .catch(error => {
        /* localArticles.map(article => {
          dispatch(addArticleToCache(parseArticle(article)));
          return null;
        }); */
      });
  };
}

export function addArticleToCache(article) {
  return {
    type: ACTIONS.ADD_ARTICLE_TO_CACHE,
    article
  };
}

// System stuff - preloaded and browser sniffing
export function loadImages(urls) {
  // handle a single url being passed
  const urlArray = Array.isArray(urls) ? urls : [urls];

  return dispatch => {
    preloadImages(urlArray).then(result => {
      dispatch(addSuccessImagePreload(result.success));
      dispatch(addFailedImagePreload(result.fail));
    });
  };
}

export function setFailedImagePreload(urls) {
  return {
    type: ACTIONS.SET_FAILED_IMAGE_PRELOAD,
    urls
  };
}

export function addFailedImagePreload(urls) {
  return {
    type: ACTIONS.ADD_FAILED_IMAGE_PRELOAD,
    urls
  };
}

export function setSuccessImagePreload(imgs) {
  return {
    type: ACTIONS.SET_SUCCESS_IMAGE_PRELOAD,
    imgs
  };
}

export function addSuccessImagePreload(imgs) {
  return {
    type: ACTIONS.ADD_SUCCESS_IMAGE_PRELOAD,
    imgs
  };
}

export function setStickySupport(sticky) {
  return {
    type: ACTIONS.SET_STICKY_SUPPORT,
    sticky
  };
}

export function queryStickySupport() {
  return dispatch => {
    var el = document.createElement("a"),
      mStyle = el.style;
    mStyle.cssText =
      "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
    dispatch(setStickySupport(mStyle.position.indexOf("sticky") !== -1));
  };
}

export function setIntroAnimDone() {
  return {
    type: ACTIONS.SET_INTRO_ANIM_DONE
  };
}
