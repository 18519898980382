import anime from "animejs";

export function introAnim(pageurl = ``, callback = () => {}) {
  const timeline = anime.timeline();
  if (typeof callback !== `function`) callback = () => {};

  timeline
    .add({
      targets: `#wsh-LoadingSymbolWrapper`,
      opacity: 0,
      easing: `linear`,
      duration: 500,
      complete: anim => {
        document.querySelector(
          `#wsh-LoadingSymbolWrapper`
        ).style.display = `none`;
        document.querySelector(`#wsh-animWrapper`).style.display = `block`;
      }
    })
    .add(
      {
        targets: ".wsh-FilligreeWrapper",
        opacity: 1,
        easing: "easeInOutSine",
        duration: 1500
      },
      1000
    )
    .add(
      {
        targets: "#topName",
        opacity: 1,
        easing: "easeInOutSine",
        duration: 2500
      },
      1000
    )
    .add(
      {
        targets: "svg.fadeIn path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 1500
      },
      1500
    )
    .add(
      {
        targets: "#subtitle",
        opacity: 1,
        easing: `linear`,
        duration: 2000,
        begin: anim => {},
        complete: anim => {
          document.querySelector(`#subtitle`).style.overflow = `hidden`;
        }
      },
      2000
    )
    .add(
      {
        targets: "#subtitle",
        opacity: 0,
        easing: `linear`,
        duration: 1000
      },
      5000
    )
    .add(
      {
        targets: `#wsh-LoadingScreen`,
        easing: `linear`,
        opacity: 0,
        duration: 1000,
        complete: anim => {
          document.querySelector(`#wsh-LoadingScreen`).style.display = `none`;
          document.querySelector(`.App`).style.overflow = `visible`;
          callback();
        }
      },
      5750
    );
}

export function quickFadeAnim(pageurl = ``, callback = () => {}) {
  document.querySelector(`#wsh-LoadingScreen`).style.display = `none`;
  document.querySelector(`.App`).style.overflow = `visible`;
}

export function sliderAnim(slider) {
  const meterStyle = window.getComputedStyle(slider.parentNode);
  const sliderStyle = window.getComputedStyle(slider);
  const step =
    (parseInt(meterStyle.width, 10) - parseInt(sliderStyle.width, 10)) / 9;
  const r = Math.floor(10 * Math.random());

  anime({
    targets: slider,
    marginLeft: `${r * step}px`,
    easing: `easeInQuad`,
    duration: 500
  });
}
