import "./RandomAlienGenerator.scss";

import React, { Component } from "react";
import descriptions from "./utils.js";
import { arrayFromPolyfill } from "../../utils/utils.js";
import { sliderAnim } from "../../utils/animation.js";

function random(arr) {
  if (!Array.isArray(arr)) return undefined;

  return arr[Math.floor(arr.length * Math.random())];
}

class RandomAlienGenerator extends Component {
  componentDidMount() {
    arrayFromPolyfill();
  }

  updateAlien() {
    const newImage = Math.floor(20 * Math.random()) + 1;
    Array.from(document.querySelectorAll(`.image-divider`)).forEach(e => {
      e.style.backgroundImage = `url('/images/${newImage}.jpg')`;
    });

    Array.from(document.querySelectorAll(`.tolerance`)).forEach(e => {
      e.innerHTML = random(descriptions.tolerance);
    });
    Array.from(document.querySelectorAll(`.range`)).forEach(e => {
      e.innerHTML = random(descriptions.range);
    });
    Array.from(document.querySelectorAll(`.gravity`)).forEach(e => {
      e.innerHTML = random(descriptions.gravity);
    });
    Array.from(document.querySelectorAll(`.temperature`)).forEach(e => {
      e.innerHTML = random(descriptions.temperature);
    });
    Array.from(document.querySelectorAll(`.pressure`)).forEach(e => {
      e.innerHTML = random(descriptions.pressure);
    });
    Array.from(document.querySelectorAll(`.environment`)).forEach(e => {
      e.innerHTML = random(descriptions.environment);
    });
    Array.from(document.querySelectorAll(`.senses`)).forEach(e => {
      e.innerHTML = random(descriptions.senses);
    });
    Array.from(document.querySelectorAll(`.food`)).forEach(e => {
      e.innerHTML = random(descriptions.food);
    });
    Array.from(document.querySelectorAll(`.social`)).forEach(e => {
      e.innerHTML = random(descriptions.social);
    });
    Array.from(document.querySelectorAll(`.lifespan`)).forEach(e => {
      e.innerHTML = random(descriptions.lifespan);
    });
    Array.from(document.querySelectorAll(`.reproduction`)).forEach(e => {
      e.innerHTML = random(descriptions.reproduction);
    });
    Array.from(document.querySelectorAll(`.percent`)).forEach(e => {
      e.innerHTML = `${5 * (Math.floor(20 * Math.random()) + 1)}%`;
    });
    Array.from(document.querySelectorAll(`.yn`)).forEach(e => {
      e.innerHTML = Math.random() >= 0.5 ? `yes` : `no`;
    });
    Array.from(document.querySelectorAll(`.rand1to5`)).forEach(e => {
      e.innerHTML = `${Math.floor(5 * Math.random()) + 1}`;
    });
    Array.from(document.querySelectorAll(`.rand1to6`)).forEach(e => {
      const r = Math.floor(5 * Math.random()) + 1;
      e.innerHTML = r < 6 ? r : `Entire body/amoeboid/living matrix`;
    });
    Array.from(document.querySelectorAll(`.rand1to11`)).forEach(e => {
      const r = Math.floor(5 * Math.random()) + 1;
      e.innerHTML = r < 11 ? r : `Entire body/amoeboid/living matrix`;
    });
    Array.from(document.querySelectorAll(`.meter > span`)).forEach(e => {
      sliderAnim(e);
    });
  }

  render() {
    /* Change Two-Column Only and One-Column only to appropriate responsive classes */
    return (
      <div id="wsh-RandomAlienGenerator">
        <h2>Random Alien Generator</h2>
        <p>
          Thanks to Tim Henderson for the use of his artwork (original link no
          longer active, but he can be found{" "}
          <a href="https://www.saatchiart.com/timh">here</a>
          ).
        </p>
        <p>
          <button
            onClick={() => {
              this.updateAlien();
            }}
          >
            Generate Alien
          </button>
        </p>
        <p className="image-divider" />
        <div id="wsh-RandomAlienGenerator-Sliders">
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Force -- Cunning</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Force
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Cunning
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Innovation -- Tradition
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Innovation
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Tradition
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Wealth -- Experience</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Wealth
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Experience
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Technology -- Nature</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Technology
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Nature
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Flexibility -- Control</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Flexibility
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Control
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Cooperation -- Exploitation
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Cooperation
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Exploitation
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Extraversion -- Introversion
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Extraversion
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Introversion
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Individualism -- Conformity
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Individualism
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Conformity
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Egalitarian -- Heirarchical
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Egalitarian
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Heirarchical
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Isolation -- Connectedness
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Isolation
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Connectedness
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Utilitarian -- Aesthetic
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Utilitarian
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Aesthetic
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Material -- Spiritual</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Material
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Spiritual
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Shame -- Forgiveness</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Shame
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Forgiveness
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">
              Assertive -- Submissive
            </div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Assertive
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Submissive
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Curiosity -- Fear</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Curiosity
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Fear
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Impulsive -- Patient</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Impulsive
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Patient
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Social -- Aloof</div>
            <div className="slider-label slider-left wsh-u-hideSmallDown">
              Social
            </div>
            <div className="meter">
              <span />
            </div>
            <div className="slider-label slider-right wsh-u-hideSmallDown">
              Aloof
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Sexual Acceptance</div>
            <div className="slider-one-label wsh-u-hideSmallDown">
              Sexual Acceptance
            </div>
            <div className="meter">
              <span />
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Importance of Progeny</div>
            <div className="slider-one-label wsh-u-hideSmallDown">
              Importance of Progeny
            </div>
            <div className="meter">
              <span />
            </div>
          </div>
          <div className="slider-group">
            <div className="wsh-u-hideMedUp center">Libido</div>
            <div className="slider-one-label wsh-u-hideSmallDown">Libido</div>
            <div className="meter">
              <span />
            </div>
          </div>
        </div>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Major Body Segments</span>
          <span className="rand1to5">--</span>
          <span className="label">Major Brains</span>
          <span className="rand1to6">--</span>
          <span className="label">Limbs/Appendages</span>
          <span className="rand1to11">--</span>
          <span className="label">Eyes/Sensory Apparati</span>
          <span className="rand1to11">--</span>
          <span className="label">Human Identifiable Face?</span>
          <span className="yn">--</span>
        </p>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Oxygen</span>
          <span className="tolerance">--</span>
          <span className="label">Methane</span>
          <span className="tolerance">--</span>
          <span className="label">Ammonia</span>
          <span className="tolerance">--</span>
          <span className="label">Silicates</span>
          <span className="tolerance">--</span>
          <span className="label">Sulfur Compounds</span>
          <span className="tolerance">--</span>
          <span className="label">Nitrogen Compounds</span>
          <span className="tolerance">--</span>
          <span className="label">Carbon Monoxide/Dioxide</span>
          <span className="tolerance">--</span>
          <span className="label">Complex Hydrocarbons</span>
          <span className="tolerance">--</span>
          <span className="label">Halogen Compounds</span>
          <span className="tolerance">--</span>
          <span className="label">Other/Exotics</span>
          <span className="tolerance">--</span>
        </p>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Radio Waves</span>
          <span className="tolerance">--</span>
          <span className="label">Microwaves</span>
          <span className="tolerance">--</span>
          <span className="label">Infrared Light</span>
          <span className="tolerance">--</span>
          <span className="label">Human Visible Light</span>
          <span className="tolerance">--</span>
          <span className="label">Ultraviolet Light</span>
          <span className="tolerance">--</span>
          <span className="label">X-Rays</span>
          <span className="tolerance">--</span>
          <span className="label">Gamma/Cosmic Rays</span>
          <span className="tolerance">--</span>
        </p>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Ancestral Environment</span>
          <span className="environment">--</span>
          <span className="label">Gravity</span>
          <span className="gravity">--</span>
          <span className="label">Gravity Tolerance</span>
          <span className="range">--</span>
          <span className="label">Temperature</span>
          <span className="temperature">--</span>
          <span className="label">Temperature Tolerance</span>
          <span className="range">--</span>
          <span className="label">Pressure</span>
          <span className="pressure">--</span>
          <span className="label">Pressure Tolerance</span>
          <span className="range">--</span>
          <span className="label">Antimatter</span>
          <span className="tolerance">--</span>
          <span className="label">Particle Radiation</span>
          <span className="tolerance">--</span>
          <span className="label">Corrosive Chemicals</span>
          <span className="tolerance">--</span>
          <span className="label">Strong Electric Field</span>
          <span className="tolerance">--</span>
          <span className="label">Strong Magnetic Field</span>
          <span className="tolerance">--</span>
        </p>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Vision</span>
          <span className="senses">--</span>
          <span className="label">Polarized Light</span>
          <span className="senses">--</span>
          <span className="label">Hearing</span>
          <span className="senses">--</span>
          <span className="label">Smell/Taste</span>
          <span className="senses">--</span>
          <span className="label">Touch/Vibration</span>
          <span className="senses">--</span>
          <span className="label">Mental/Psychic</span>
          <span className="senses">--</span>
          <span className="label">Memory</span>
          <span className="senses">--</span>
          <span className="label">Time</span>
          <span className="senses">--</span>
          <span className="label">Balance/Body Position</span>
          <span className="senses">--</span>
          <span className="label">Acceleration/Body Motion</span>
          <span className="senses">--</span>
          <span className="label">Temperature</span>
          <span className="senses">--</span>
          <span className="label">Pain</span>
          <span className="senses">--</span>
          <span className="label">Electric Fields</span>
          <span className="senses">--</span>
          <span className="label">Magnetic Fields</span>
          <span className="senses">--</span>
          <span className="label">Radiation</span>
          <span className="senses">--</span>
          <span className="label">Exotic/Other</span>
          <span className="senses">--</span>
        </p>
        <p className="image-divider" />
        <p className="center">
          <span className="label">Ancestral Niche</span>
          <span className="food">--</span>
          <span className="label">Ancestral Sociability</span>
          <span className="social">--</span>
          <span className="label">Lifespan</span>
          <span className="lifespan">--</span>
          <span className="label">Resilience/Ability to Heal</span>
          <span className="senses">--</span>
          <span className="label">Resistance to Disease</span>
          <span className="senses">--</span>
          <span className="label">Number of Genders</span>
          <span className="rand1to5">--</span>
          <span className="label">Method of Reproduction</span>
          <span className="reproduction">--</span>
          <span className="label">Offspring Survival Rate</span>
          <span className="percent">--</span>
        </p>
      </div>
    );
  }
}

export default RandomAlienGenerator;
