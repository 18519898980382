import React, { Component } from "react";

import "./Blurb.scss";

export default class Blurb extends Component {
  static defaultProps = {
    quote: `need quote`,
    img: `need img`,
    blurber: `need blurb`
  };

  render() {
    return (
      <section className="wsh-LandingBlurb">
        <blockquote>
          <p>{this.props.quote}</p>
        </blockquote>
        <cite>
          <img src={this.props.img} alt="" />
          <p>{this.props.blurber}</p>
        </cite>
      </section>
    );
  }
}
