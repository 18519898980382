import React, { Component } from "react";

import "./LandingCatSpacer.scss";

export default class LandingCatSpacer extends Component {
  render() {
    return (
      <section className="wsh-LandingCatSpacer">
        <section className="wsh-LandingSpacerOverlay">
          <h1>I Wasn't Sure&hellip;</h1>
          <p>&hellip;what to put here, but the empty space bothered me.</p>
          <p>So here's a picture of my cat who hates people. Hi!</p>
        </section>
      </section>
    );
  }
}
