import React from "react";

import "./BookCatalogSkeletonEntry.scss";

function BookCatalogSkeletonEntry() {
  return (
    <section className="wsh-flexCatalogEntry">
      <div className="wsh-flexCatalogSkeletonImg" />
      <p className="wsh-flexCatalogSkeletonTitle" />
    </section>
  );
}

export default BookCatalogSkeletonEntry;
