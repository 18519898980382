import "./FileNotFound.scss";

import React, { Component } from "react";

export default class FileNotFound extends Component {
  render() {
    return (
      <section className="wsh-404">
        <h3>Under Construction</h3>
        <p>
          Apologies. This part of the site is still under construction. Click my
          name at the top to get back to the main page or use the links above.
        </p>
        <p>Check back soon for more content!</p>
      </section>
    );
  }
}
