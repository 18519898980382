import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { showModalSignup } from "../../reducer/actions";
import { Button } from "@rmwc/button";
import { Ripple } from "@rmwc/ripple";
import { Elevation } from "@rmwc/elevation";

import "./FooterWithSignup.scss";

const mapDispatchToProps = dispatch => {
  return {
    showModalSignup: () => {
      dispatch(showModalSignup());
    }
  };
};

class FooterWithSignup extends Component {
  render() {
    return (
      <Fragment>
        <section id="wsh-ShortSignup">
          <Ripple accent>
            <Elevation z={20} id="wsh-ShortSignupImage">
              <img
                src="https://www.chriswsh.com/images/2017-DR-PI-large.png"
                alt=""
                onClick={() => {
                  this.props.showModalSignup();
                }}
              />
            </Elevation>
          </Ripple>
          <section id="wsh-ShortSignupText">
            <h1>Your Free Art Is Waiting!</h1>
            <p>
              <b>FREE!!!</b> <i>Free.</i> Free!! Free.{" "}
              <b>
                <i>Free!</i>
              </b>{" "}
              Free?
            </p>
            <p>
              <b>*author hyperventilates and falls over*</b>
            </p>
            <p>
              <Button
                label="GET THE FREE ART"
                raised
                ripple
                theme={[`secondaryBg`]}
                onClick={() => {
                  this.props.showModalSignup();
                }}
              />
            </p>
          </section>
        </section>
        <footer id="wsh-Footer">
          <section>
            <p>&copy; Copyright 2018 or later</p>
            <p>Thanks for Visiting!</p>
          </section>
        </footer>
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(FooterWithSignup);
