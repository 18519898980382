import "./ArticleCatalog.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Elevation } from "@rmwc/elevation";
import Filligree from "../Filligree/Filligree";
import _ from "lodash";

const headerMap = Object.freeze([
  `Creative Writing - Basic`,
  `Creative Writing - Intermediate`,
  `Creative Writing - Advanced`,
  `Creative Writing Resources`
]);

class ArticleCatalog extends Component {
  renderSkeleton(num = 1) {
    return (
      <p>
        {Array.from({ length: num }).reduce(
          (acc, cur, index) =>
            acc.concat(
              <section
                className="wsh-ArticleCatalogSkeletonEntry"
                key={`${index}`}
              />
            ),
          []
        )}
      </p>
    );
  }

  renderGroup(articleGroupNumber, articleGroup) {
    return (
      <div key={articleGroupNumber}>
        <Elevation z={10} wrap>
          <h3>
            <Filligree addClass="wsh-u-hideMedDown" />
            {headerMap[articleGroupNumber]}
            <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
          </h3>
        </Elevation>
        {articleGroup.map(article => {
          if (article.externalUrl)
            return (
              <p key={article.externalUrl}>
                <a href={article.externalUrl}>{article.title}</a>
              </p>
            );
          return (
            <p key={article.reactUrl}>
              <Link to={`/craft/${article.reactUrl}`}>{article.title}</Link>
            </p>
          );
        })}
      </div>
    );
  }

  render() {
    if (_.isEmpty(this.props.catalog))
      return (
        <div id="wsh-ArticleCatalog">
          <Elevation z={10} wrap>
            <h3>
              <Filligree addClass="wsh-u-hideMedDown" />
              Articles...
              <Filligree addClass="wsh-u-hideMedDown" pointUp={false} />
            </h3>
          </Elevation>
          {this.renderSkeleton(7)}
        </div>
      );

    return (
      <div id="wsh-ArticleCatalog">
        {Object.keys(this.props.catalog).map(key => {
          return this.renderGroup(key, this.props.catalog[key]);
        })}
      </div>
    );
  }
}

ArticleCatalog.propTypes = {
  catalog: PropTypes.object.isRequired
};

ArticleCatalog.defaultProps = {
  catalog: {}
};

export default ArticleCatalog;
