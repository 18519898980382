import stopword from "stopword";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

// parse Drupal API raw data
export function parseBook(book) {
  const parsedBook = {};

  parsedBook.title = book.title.length ? book.title[0].value : ``;
  parsedBook.body = book.body.length
    ? book.body[0].value.replace(/[\n\r]/g, ``).trim()
    : ``;
  parsedBook.type = book.field_type.length ? book.field_type[0].value : ``;
  parsedBook.amazonPurchaseUrl = book.field_amazon_purchase_url.length
    ? book.field_amazon_purchase_url[0].value
    : ``;
  parsedBook.bnPurchaseUrl = book.field_b_n_purchase_url.length
    ? book.field_b_n_purchase_url[0].value
    : ``;
  parsedBook.itunesPurchaseUrl = book.field_itunes_purchase_url.length
    ? book.field_itunes_purchase_url[0].value
    : ``;
  parsedBook.koboPurchaseUrl = book.field_kobo_purchase_url.length
    ? book.field_kobo_purchase_url[0].value
    : ``;
  parsedBook.playsterPurchaseUrl = book.field_playster_purchase_url.length
    ? book.field_playster_purchase_url[0].value
    : ``;
  parsedBook.scribdPurchaseUrl = book.field_scribed_purchase_url.length
    ? book.field_scribed_purchase_url[0].value
    : ``;
  parsedBook.smashwordsPurchaseUrl = book.field_smashwords_purchase_url.length
    ? book.field_smashwords_purchase_url[0].value
    : ``;
  parsedBook.otherPurchaseName = book.field_other_purchase_name.length
    ? book.field_other_purchase_name[0].value
    : ``;
  parsedBook.otherPurchaseUrl = book.field_other_purchase_url.length
    ? book.field_other_purchase_url[0].value
    : ``;
  parsedBook.otherPurchaseLinkButtonUrl = book.field_other_purchase_link_button
    .length
    ? book.field_other_purchase_link_button[0].url
    : ``;
  parsedBook.otherPurchaseLinkButtonUrlSmall = book
    .field_other_purchase_sm_button.length
    ? book.field_other_purchase_sm_button[0].url
    : ``;
  parsedBook.imageUrl = book.field_image.length ? book.field_image[0].url : ``;
  parsedBook.publisher = book.field_publisher.length
    ? book.field_publisher[0].value
    : ``;
  parsedBook.publisherUrl = book.field_publisher_url.length
    ? book.field_publisher_url[0].value
    : ``;

  parsedBook.series = book.field_series.length
    ? book.field_series[0].value
    : ``;

  parsedBook.seriesNumber = book.field_series_number.length
    ? book.field_series_number[0].value
    : ``;

  parsedBook.reactUrl = `${stopword
    .removeStopwords(
      parsedBook.title
        .replace(/[^\w\s]/g, ``)
        .trim()
        .split(` `)
    )
    .join(`-`)
    .toLowerCase()}`;

  return parsedBook;
}

export function parseArticle(article) {
  const parsedArticle = {};

  parsedArticle.subgroup = article.field_subgroup
    ? article.field_subgroup[0].value
    : ``;
  parsedArticle.title = article.title.length ? article.title[0].value : ``;
  parsedArticle.article = article.body.length ? article.body[0].value : ``;
  parsedArticle.reactUrl = `${stopword
    .removeStopwords(
      parsedArticle.title
        .replace(/[^\w\s]/g, ``)
        .trim()
        .split(` `)
    )
    .join(`-`)
    .toLowerCase()}`;
  parsedArticle.externalUrl = article.field_external_url.length
    ? article.field_external_url[0].value
    : ``;

  return parsedArticle;
}

export function preloadImages(urls) {
  if (!Array.isArray(urls)) return Promise.resolve(false);
  if (!Array.length) return Promise.resolve(false);

  return Promise.all(
    urls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          reject(url);
        };
        img.src = url;
      })
        .then(success => ({ success }))
        .catch(fail => ({ fail }));
    })
  ).then(resultArray => {
    return resultArray.reduce(
      (aggregate, item) => {
        item.fail
          ? aggregate.fail.push(item.fail)
          : aggregate.success.push(item.success);

        return aggregate;
      },
      { success: [], fail: [], total: urls.length }
    );
  });
}

export function sanitizeHTML(html) {
  return { __html: DOMPurify.sanitize(html) };
}

export function arrayFromPolyfill() {
  // Production steps of ECMA-262, Edition 6, 22.1.2.1
  if (!Array.from) {
    Array.from = (function() {
      var toStr = Object.prototype.toString;
      var isCallable = function(fn) {
        return (
          typeof fn === "function" || toStr.call(fn) === "[object Function]"
        );
      };
      var toInteger = function(value) {
        var number = Number(value);
        if (isNaN(number)) {
          return 0;
        }
        if (number === 0 || !isFinite(number)) {
          return number;
        }
        return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      var maxSafeInteger = Math.pow(2, 53) - 1;
      var toLength = function(value) {
        var len = toInteger(value);
        return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike /*, mapFn, thisArg */) {
        // 1. Let C be the this value.
        var C = this;

        // 2. Let items be ToObject(arrayLike).
        var items = Object(arrayLike);

        // 3. ReturnIfAbrupt(items).
        if (arrayLike == null) {
          throw new TypeError(
            "Array.from requires an array-like object - not null or undefined"
          );
        }

        // 4. If mapfn is undefined, then let mapping be false.
        var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
        var T;
        if (typeof mapFn !== "undefined") {
          // 5. else
          // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
          if (!isCallable(mapFn)) {
            throw new TypeError(
              "Array.from: when provided, the second argument must be a function"
            );
          }

          // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
          if (arguments.length > 2) {
            T = arguments[2];
          }
        }

        // 10. Let lenValue be Get(items, "length").
        // 11. Let len be ToLength(lenValue).
        var len = toLength(items.length);

        // 13. If IsConstructor(C) is true, then
        // 13. a. Let A be the result of calling the [[Construct]] internal method
        // of C with an argument list containing the single item len.
        // 14. a. Else, Let A be ArrayCreate(len).
        var A = isCallable(C) ? Object(new C(len)) : new Array(len);

        // 16. Let k be 0.
        var k = 0;
        // 17. Repeat, while k < len… (also steps a - h)
        var kValue;
        while (k < len) {
          kValue = items[k];
          if (mapFn) {
            A[k] =
              typeof T === "undefined"
                ? mapFn(kValue, k)
                : mapFn.call(T, kValue, k);
          } else {
            A[k] = kValue;
          }
          k += 1;
        }
        // 18. Let putStatus be Put(A, "length", len, true).
        A.length = len;
        // 20. Return A.
        return A;
      };
    })();
  }
}

// from MDN
export function storageAvailable(type) {
  try {
    var storage = window[type],
      x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}
