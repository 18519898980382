import React, { Fragment, Component } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../Header/Header";
import FileNotFound from "../FileNotFound/FileNotFound";
import Landing from "../Landing/Landing";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import About from "../About/About";
import ArticleCatalogContainer from "../ArticleCatalogContainer/ArticleCatalogContainer";
import BookCatalogContainer from "../BookCatalogContainer/BookCatalogContainer";
import FooterWithSignup from "../FooterWithSignup/FooterWithSignup";

import "./Main.scss";

export default class Main extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <main id="wsh-Main">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/about" component={About} />
            <Route path="/craft" component={ArticleCatalogContainer} />
            <Route path="/stories" component={BookCatalogContainer} />
            <Route component={FileNotFound} />
          </Switch>
          <FooterWithSignup />
        </main>
      </Fragment>
    );
  }
}
