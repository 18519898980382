import React, { Component } from "react";
import {
  Main,
  Loader,
  ModalMailingList,
  MailingListLeaderFullScreen,
  Quack
} from "./components/index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducer/reducer";
import {
  loadBookCatalogFromDrupalAPI,
  queryStickySupport,
  setIntroAnimDone
} from "./reducer/actions";

import { introAnim, quickFadeAnim } from "./utils/animation";

import WebFont from "webfontloader";

import "./App.scss";

const store = createStore(reducer, applyMiddleware(thunk));

class App extends Component {
  /* Play animations after component is loaded */
  componentDidMount() {
    /* These fonts are declared in App.css, and have server-local copies.
    We wait until they're active to run the animation to avoid FOUT.
    Hopefully Google is faster than local, so we attempt from there.
    Whether they load or not, run the intro animation */
    WebFont.load({
      google: {
        families: [`Marcellus SC`, "Material Icons"]
      },
      /* Don't run intro animation if self-referred or site reached through
      forward/back. Just fade out loading screen */
      active: () => {
        if (
          document.referrer.indexOf(window.location.hostname) !== -1 ||
          window.performance.navigation.type === 2 ||
          window.performance.navigation.type === 1 ||
          window.performance.navigation.type === `reload` ||
          window.performance.navigation.type === `back_forward` ||
          window.performance.navigation.type === `pre_render`
        ) {
          quickFadeAnim(window.location.href, () => {
            store.dispatch(setIntroAnimDone());
          });
        } else {
          introAnim(window.location.href, () => {
            store.dispatch(setIntroAnimDone());
          });
        }
      },
      inactive: () => {
        if (
          document.referrer.indexOf(window.location.hostname) !== -1 ||
          window.performance.navigation.type === 2 ||
          window.performance.navigation.type === 1 ||
          window.performance.navigation.type === `reload` ||
          window.performance.navigation.type === `back_forward` ||
          window.performance.navigation.type === `pre_render`
        ) {
          quickFadeAnim(window.location.href, () => {
            store.dispatch(setIntroAnimDone());
          });
        } else {
          introAnim(window.location.href, () => {
            store.dispatch(setIntroAnimDone());
          });
        }
      },
      timeout: 2000
    });

    /* The intro animation buys some time for preload already.
    Instead of waiting for everything to preload before starting
    the animation, we just launch the preloading process for a bit
    of a head start */
    store.dispatch(
      loadBookCatalogFromDrupalAPI(
        store.getState().stories[Symbol.for(`catalogURL`)]
      )
    );

    // check if the browser supports sticky positioning
    store.dispatch(queryStickySupport());
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App" style={{ overflow: `hidden` }}>
            <Switch>
              <Route
                exact
                path="/freebook"
                component={MailingListLeaderFullScreen}
              />
              <Route exact path="/quack" component={Quack} />
              <Route component={Main} />
            </Switch>
            <Loader />
            <ModalMailingList />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
