import React, { Component } from "react";
import { connect } from "react-redux";
import { showModalSignup } from "../../reducer/actions";
import { Button } from "@rmwc/button";

import "./Quack.scss";

const mapDispatchToProps = dispatch => {
  return {
    showModalSignup: () => {
      dispatch(showModalSignup());
    }
  };
};

class Quack extends Component {
  render() {
    return (
      <section className={`wsh-hero fullscreen`}>
        <section id="wsh-hero-image">
          <img src="/images/Duck-Services-400x429.jpg" alt="" />
        </section>
        <section id="wsh-hero-text">
          <h1>You Are Here for the Ducks, I Presume&hellip;</h1>
          <p>
            Sometimes, I ramble to my mailing list about issues I'm having with
            my current writing. I appreciate the time people take to be there,
            and I call that providing duck services, because sometimes
            explaining yourself to a rubber duck helps.
          </p>
          <p>
            If you join, you not only get a series of nifty badges, but you also
            get a free novel! <i>Dick Richards: Private Eye</i> is a satirical
            cyberpunk detective novel (though I wouldn't recommend it for anyone
            under 16).
          </p>
          <p>But enough about that. On to the ducks!</p>
          <p>
            <Button
              label="Give Me Ducks!"
              raised
              ripple
              theme={[`secondaryBg`]}
              onClick={() => {
                this.props.showModalSignup();
              }}
            />
          </p>
        </section>
      </section>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Quack);
