import "./DramaCombinatrixEngine.scss";

import React, { Component } from "react";

import situations from "./utils.js";

class DramaCombinatrixEngine extends Component {
  renderDrama() {
    const mainPlot = situations.random();
    let subPlot, complication;

    do {
      subPlot = situations.random();
    } while (subPlot.summary === mainPlot.summary);

    do {
      complication = situations.random();
    } while (
      complication.summary === mainPlot.summary ||
      complication.summary === subPlot.summary
    );

    this.mainPlot.innerHTML = situations.format(mainPlot);
    this.subPlot.innerHTML = situations.format(subPlot);
    this.complication.innerHTML = situations.format(complication);

    // scroll to the top of the display
    window.scrollTo(
      0,
      this.dramaDisplay.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top
    );
  }

  renderShortTable() {
    return situations.situations.reduce((acc, curr, i) => {
      return acc.concat(
        <tr key={i}>
          <td>{`${((i / 6) >> 0) + 1}`}</td>
          <td>{`${(i % 6) + 1}`}</td>
          <td>{curr.summary}</td>
        </tr>
      );
    }, []);
  }

  render() {
    return (
      <div id="wsh-DramaCombinatrix">
        <h2>Drama Combinatrix Engine</h2>
        <p>
          The Drama Combinatrix Engine is a one-stop shop for computer-generated
          drama, based on{" "}
          <a href="https://en.wikipedia.org/wiki/The_Thirty-Six_Dramatic_Situations">
            The Thirty-Six Dramatic Situations
          </a>
          .
        </p>
        <p>
          Thirty-six just happens to be the number of possibilities inherent by
          rolling two 6-sided dice, so lets get started. (Thanks to Travis for
          mentioning the Wikipedia page a long time ago, before StumbleUpon.)
        </p>
        <section
          ref={me => {
            this.dramaDisplay = me;
          }}
        >
          <hr />
          <h3>Main Plot</h3>
          <p
            ref={me => {
              this.mainPlot = me;
            }}
          />
          <h3>Subplot</h3>
          <p
            ref={me => {
              this.subPlot = me;
            }}
          />
          <h3>Complication</h3>{" "}
          <p
            ref={me => {
              this.complication = me;
            }}
          />
          <button
            onClick={() => {
              this.renderDrama();
            }}
          >
            Combinate Drama!
          </button>
          <hr />
        </section>
        <section className="wsh-u-hideMedUp">
          <h3>Dice Table</h3>
          <table cols="3" rows="37" id="thirtysixsmall">
            <tbody>
              <tr>
                <th>First (d6)</th>
                <th>Second (d6)</th>
                <th>Result</th>
              </tr>
              {this.renderShortTable()}
            </tbody>
          </table>
        </section>
        <section className="wsh-u-hideSmallDown">
          <h3>Dice Table</h3>
          <table cols="7" rows="7" id="thirtysix">
            <tbody>
              <tr>
                <th>2d6</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
              </tr>
              <tr>
                <th>1</th>
                <td>Supplication</td>
                <td>Deliverance</td>
                <td>Crime pursued by vengeance</td>
                <td>Vengeance taken for kin upon kin</td>
                <td>Pursuit</td>
                <td>Disaster</td>
              </tr>
              <tr>
                <th>2</th>
                <td>Falling prey to cruelty/misfortune</td>
                <td>Revolt</td>
                <td>Daring enterprise</td>
                <td>Abduction</td>
                <td>The enigma</td>
                <td>Obtaining</td>
              </tr>
              <tr>
                <th>3</th>
                <td>Enmity of kin</td>
                <td>Rivalry of kin</td>
                <td>Murderous adultery</td>
                <td>Madness</td>
                <td>Fatal imprudence</td>
                <td>Involuntary crimes of love</td>
              </tr>
              <tr>
                <th>4</th>
                <td>Slaying of kin unrecognized</td>
                <td>Self-sacrifice for an ideal</td>
                <td>Self-sacrifice for kin</td>
                <td>All sacrificed for passion</td>
                <td>Necessity of sacrificing loved ones</td>
                <td>Rivalry of superior vs. inferior</td>
              </tr>
              <tr>
                <th>5</th>
                <td>Adultery</td>
                <td>Crimes of love</td>
                <td>Discovery of the dishonour of a loved one</td>
                <td>Obstacles to love</td>
                <td>An enemy loved</td>
                <td>Ambition</td>
              </tr>
              <tr>
                <th>6</th>
                <td>Conflict with a god</td>
                <td>Mistaken jealousy</td>
                <td>Erroneous judgement</td>
                <td>Remorse</td>
                <td>Recovery of a lost one</td>
                <td>Loss of loved ones</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    );
  }
}

export default DramaCombinatrixEngine;
