const tolerance = Object.freeze([
  `Dies after brief exposure`,
  `Dies after prolonged/intense exposure`,
  `Causes noticeable discomfort/Avoids if possible`,
  `Doesn't like, but will tolerate`,
  `Enjoys, but won't necessarily seek out`,
  `Necessary for full health/Seeks out if possible`,
  `Necessary for optimum health`,
  `Dies after prolonged/intense deprivation`,
  `Dies after brief deprivation`
]);
const gravity = Object.freeze([
  `No gravity (interstellar/intergalactic space)`,
  `Little gravity (<1% of Earth)`,
  `Moon gravity (17% of Earth)`,
  `Mars gravity (38% of Earth)`,
  `Earth gravity`,
  `Jupiter gravity (236% of Earth)`,
  `Super-Jupiter gravity (1,000% of Earth)`,
  `Sun gravity (2,707% of Earth)`,
  `Superstellar gravity (>10,000% of Earth)`,
  `Relativistic gravity (neutron stars, black holes)`
]);
const range = Object.freeze([
  `Extremely limited survival range (&plusmn;1%)`,
  `Limited survival range (&plusmn;10%)`,
  `Okay survival range (&plusmn;50%)`,
  `Decent survival range (&plusmn;100%)`,
  `Large survival range (&plusmn;250%)`,
  `Great survival range (&plusmn;2,000%)`,
  `Impressive survival range (&plusmn;10,000%)`,
  `Can survive in all ranges, dies at extremes`,
  `Can survive in all ranges, goes dormant at extremes`,
  `Can survive in all ranges with full function`
]);
const temperature = Object.freeze([
  `Cosmic background radiation (2.7 K)`,
  `Coldest temperature recorded on moon (60 K)`,
  `Highest discovered superconductivity (138 K)`,
  `Coldest temperature on earth (184 K)`,
  `Average temperaure on earth (290 K)`,
  `Hottest temperature on earth (330 K)`,
  `Inside volcanos (1373 K)`,
  `Surface of Sun (5,800 K)`,
  `Center of Sun (1.57x10^7 K)`,
  `Electroweak unification temperature (10^15 K)`
]);
const pressure = Object.freeze([
  `Space (almost 0 earth atmospheres)`,
  `Surface of Mars (~0.006 earth atmospheres)`,
  `Armstrong Limit (~.06 earth atmospheres)`,
  `Surface of Earth (1 earth atmosphere)`,
  `Surface of Venus (90 earth atmospheres)`,
  `Ocean floor average (355 earth atmospheres)`,
  `Pressure at the bottom of Marianas Trench (1,100 earth atmospheres)`,
  `Pressure at center of earth (~3.5x10^6 earth atmospheres)`,
  `Pressure inside neutron star (>10^29 earth atmospheres)`
]);
const environment = Object.freeze([
  `Terrestrial (surface of a rocky world)`,
  `Aquatic (surrounded by fluid)`,
  `Subterranean (surrounded by solid matter)`,
  `Avian (flying through atmosphere of rocky world)`,
  `Gaseous (atmosphere of gas giant)`,
  `Space`,
  `Other/Exotic (wormhole, inside star, plasma)`
]);
const senses = Object.freeze([
  `Nonexistent`,
  `Vestigial/Rudimentary`,
  `Poor`,
  `Below Average`,
  `Average`,
  `Above Average`,
  `Good`,
  `Excellent`,
  `Astounding`,
  `Other`
]);
const food = Object.freeze([
  `Autotroph (makes own food from nonliving compounds)`,
  `Parasite`,
  `Microbial Feeder`,
  `Herbivore`,
  `Herbivore (apex species)`,
  `Omnivore`,
  `Omnivore (apex species)`,
  `Carnivore`,
  `Carnivore (apex species)`,
  `Psychovore (feeds on minds/emotions/mental energy)`
]);
const social = Object.freeze([
  `Solitary (one per galaxy)`,
  `Solitary (one per planet)`,
  `Solitary`,
  `Mating Pair`,
  `Family`,
  `Herd/Flock (related)`,
  `Herd/Flock (unrelated)`,
  `Colony/Hive (separate minds)`,
  `Colony/Hive (hive mind)`,
  `Unending swarm`
]);
const lifespan = Object.freeze([
  `Days`,
  `Weeks`,
  `Decades (approx 30-50 years)`,
  `Decades (approx 50-70 years)`,
  `Decades (approx 70-100 years)`,
  `Low to Mid Centuries`,
  `Mid to High Centuries`,
  `Millenia`,
  `Aeons`
]);
const reproduction = Object.freeze([
  `Budding/Cloning (natural)`,
  `Eggs (parented)`,
  `Eggs (unparented)`,
  `Eggs (live birth)`,
  `Embryos (marsupial)`,
  `Embryos (live birth)`,
  `Asexual`,
  `Lab created (cloning, organism building)`,
  `Abiogenesis (spontaneously arise)`,
  `Exotic/Other`
]);

export const descriptions = Object.freeze({
  tolerance,
  gravity,
  range,
  temperature,
  pressure,
  environment,
  senses,
  food,
  social,
  lifespan,
  reproduction
});

export default descriptions;
