import "./Loader.scss";

import React, { Fragment } from "react";
import LoaderSymbol from "../LoaderSymbol/LoaderSymbol";
import Filligree from "../Filligree/Filligree";

const Loader = () => {
  return (
    <Fragment>
      <div id="wsh-LoadingScreen">
        <div id="wsh-animWrapper" style={{ display: `none` }}>
          <Filligree fadeIn={true} />
          <h1 id="topName" style={{ opacity: 0 }}>
            Chris Wong Sick Hong
          </h1>
          <Filligree fadeIn={true} pointUp={false} />
          <h2 id="subtitle" style={{ opacity: 0 }}>
            Slipstream Sci-Fi/Fantasy
          </h2>
        </div>
        <div id="wsh-LoadingSymbolWrapper" style={{ opacity: 0.75 }}>
          <LoaderSymbol />
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
