import React, { Component } from "react";

import "./LandingDescription.scss";

export default class LandingDescription extends Component {
  render() {
    return (
      <section className="wsh-LandingDescription">
        <h1>Cyberpunk with Snark and Magic</h1>
        <p>
          <i>Dick Richards: Private Eye</i> is cyberpunk detective noir, crammed
          in a blender with sci-fi, fantasy and satire. I'd like to give you
          more so you can tell if it's your style, but I'm bad at knowing what
          my writing's like so I asked an AI to do it for me.
        </p>
        <p>
          When they don't think one of the main themes is interior furnishing,
          our new robotic overlords think these books are similar to mine:
        </p>
        <p>
          <i>Reentry</i> from Peter Cawdron's Retrograde Series.{" "}
          <i>No Direction Home</i> by Norman Spinrad.{" "}
          <i>Celestial Voyages: The Moon</i> by Jeff Provine.{" "}
          <i>With Friends Like These</i> by Alan Dean Foster.
        </p>
      </section>
    );
  }
}
