import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showModalSignup } from "../../reducer/actions";
import Blurb from "../Blurb/Blurb";
import { Button } from "@rmwc/button";

import Countdown from "react-countdown-now";

import "./About.scss";

const mapDispatchToProps = dispatch => {
  return {
    showModalSignup: () => {
      dispatch(showModalSignup());
    }
  };
};

const renderer = ({
  total,
  days,
  hours,
  minutes,
  seconds,
  milliseconds,
  completed
}) => {
  if (completed) {
    return (
      <Fragment>
        <h1>Too Late!</h1>
        <p>Due to budget cuts, you'll have to imagine this for yourself...</p>
      </Fragment>
    );
  } else {
    return (
      <h1>
        {days}:{hours}:{minutes}:{seconds}
      </h1>
    );
  }
};

class About extends Component {
  render() {
    return (
      <section id="wsh-aboutMe">
        <h3>About Me</h3>
        <p>
          Thanks for stopping by. As you may have guessed, I am a writer. But
          you may be asking yourself: What <i>kind</i> of writer is he?
        </p>
        <p className="center">
          <img src="/images/winner.jpg" alt="The Best Kind!" />
          <br />
          (Spoiler alert: The best kind)
        </p>
        <p>
          When young, I always preferred the books with quality bound covers and
          the title printed on the spine. The ones with the confidence to be
          themselves and let the writing and ideas speak for them.
        </p>
        <p>
          I still love how science fiction and fantasy explore the human
          consequences of big ideas, and satire because sometimes funhouse
          mirrors have the most accurate reflections.
        </p>
        <p>I also throw in enough explosions to keep things interesting.</p>
        <p>
          If you'd like to learn more, the Dick Richards series&mdash;or more
          formally{" "}
          <i>The Life and Times of Dick Richards (And Other People Too)</i>
          &mdash;is a cyberpunk/urban fantasy/detective noir hybrid. A
          hardboiled detective story set in a cyberpunk setting with magic and
          elves.
        </p>
        <p>
          <b>
            To check the first book out for free, sign up for my mailing list
            below.
          </b>
        </p>
        <p className="center">
          <Button
            label="Free Book!"
            raised
            ripple
            theme={[`secondaryBg`]}
            onClick={() => {
              this.props.showModalSignup();
            }}
          />
        </p>
        <p>
          (In the interests of social responsibility, I don't recommend it for
          anyone under 16.)
        </p>
        <Blurb
          quote="This Noir Cyberpunk adventure is sure to thrill and entertain."
          img="/images/smallhead.png"
          blurber="Kevin Noel Olsen, Author of Green Lama and Secret Agent X stories"
        />
        <p>
          Act now! Before I get bored and start rolling around on the floor
          again!
        </p>
        <Countdown date={Date.now() + 120000} renderer={renderer} />
      </section>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(About));
