import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import ArticleCatalog from "../ArticleCatalog/ArticleCatalog";
import Article from "../Article/Article";
import _ from "lodash";
import RandomAlienGenerator from "../RandomAlienGenerator/RandomAlienGenerator";
import DramaCombinatrixEngine from "../DramaCombinatrixEngine/DramaCombinatrixEngine";

import { loadArticleCatalogFromDrupalAPI } from "../../reducer/actions";

const mapStateToProps = state => {
  return {
    catalog: state.articles,
    url: state.articles[Symbol.for(`articleURL`)]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadArticles: url => {
      dispatch(loadArticleCatalogFromDrupalAPI(url));
    }
  };
};

class ArticleCatalogContainer extends Component {
  render() {
    if (_.isEmpty(this.props.catalog[Symbol.for(`fullArticleInfo`)])) {
      this.props.loadArticles(this.props.url);
    }

    return (
      <div>
        <Switch>
          <Route
            exact
            path="/craft"
            render={props => (
              <ArticleCatalog catalog={this.props.catalog} {...props} />
            )}
          />
          <Route
            exact
            path="/craft/random-alien-generator"
            component={RandomAlienGenerator}
          />
          <Route
            exact
            path="/craft/drama-combinatrix-engine"
            component={DramaCombinatrixEngine}
          />
          <Route
            path="/craft/:title"
            render={props => (
              <Article catalog={this.props.catalog} {...props} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCatalogContainer);
