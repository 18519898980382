import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Filligree extends Component {
  static propTypes = {
    fadeIn: PropTypes.bool.isRequired,
    pointUp: PropTypes.bool.isRequired,
    ltr: PropTypes.bool.isRequired
  };

  static defaultProps = {
    fadeIn: false,
    pointUp: true,
    ltr: true
  };

  render() {
    const transform = [];
    if (!this.props.pointUp) transform.push(`scaleY(-1)`);
    if (this.props.ltr) transform.push(`scaleX(-1)`);

    return (
      <section
        className={`wsh-FilligreeWrapper${
          this.props.addClass ? ` ${this.props.addClass}` : ``
        }`}
        style={{ opacity: this.props.fadeIn ? 0 : 1 }}
      >
        <svg
          className={this.props.fadeIn ? `fadeIn` : undefined}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="632"
          height="47.46452"
          viewBox="0 0 631.99998 47.464518"
          overflow="visible"
          enableBackground="new 0 0 316.333 51.629"
          id="svg94"
          style={{
            overflow: `visible`,
            transform: transform.join(` `),
            maxWidth: `100%`
          }}
        >
          <g id="Design" transform="matrix(2,0,0,-2,-0.666,103.25821)">
            <defs id="defs5">
              <filter
                id="Adobe_OpacityMaskFilter"
                filterUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="316"
                height="25.219"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  id="feColorMatrix2"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="316"
              height="25.219"
              id="XMLID_5_"
            >
              <g id="g36" style={{ filter: `url(#Adobe_OpacityMaskFilter)` }}>
                <linearGradient
                  id="XMLID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="-1.666"
                  y1="12.3979"
                  x2="316.66699"
                  y2="12.3979"
                >
                  <stop
                    offset="0"
                    style={{ stopColor: `#000000` }}
                    id="stop7"
                  />
                  <stop
                    offset="0.0851"
                    style={{ stopColor: `#393939` }}
                    id="stop9"
                  />
                  <stop
                    offset="0.1984"
                    style={{ stopColor: `#7f7f7f` }}
                    id="stop11"
                  />
                  <stop
                    offset="0.3008"
                    style={{ stopColor: `#B6B6B6` }}
                    id="stop13"
                  />
                  <stop
                    offset="0.3883"
                    style={{ stopColor: `#DEDEDE` }}
                    id="stop15"
                  />
                  <stop
                    offset="0.4576"
                    style={{ stopColor: `#F6F6F6` }}
                    id="stop17"
                  />
                  <stop
                    offset="0.5"
                    style={{ stopColor: `#FFFFFF` }}
                    id="stop19"
                  />
                  <stop
                    offset="0.5424"
                    style={{ stopColor: `#F6F6F6` }}
                    id="stop21"
                  />
                  <stop
                    offset="0.6117"
                    style={{ stopColor: `#DEDEDE` }}
                    id="stop23"
                  />
                  <stop
                    offset="0.6992"
                    style={{ stopColor: `#B6B6B6` }}
                    id="stop25"
                  />
                  <stop
                    offset="0.8016"
                    style={{ stopColor: `#7F7F7F` }}
                    id="stop27"
                  />
                  <stop
                    offset="0.9149"
                    style={{ stopColor: `#393939` }}
                    id="stop29"
                  />
                  <stop
                    offset="1"
                    style={{ stopColor: `#000000` }}
                    id="stop31"
                  />
                </linearGradient>
                <rect
                  x="-1.666"
                  y="-1.602"
                  width="318.33301"
                  height="28"
                  id="rect34"
                  style={{
                    fill: `url(#XMLID_6_)`,
                    stroke: `none`
                  }}
                />
              </g>
            </mask>
            <defs id="defs50">
              <filter
                id="Adobe_OpacityMaskFilter_1_"
                filterUnits="userSpaceOnUse"
                x="0.333"
                y="26.41"
                width="316"
                height="25.219"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  id="feColorMatrix47"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="0.333"
              y="26.41"
              width="316"
              height="25.219"
              id="XMLID_7_"
            >
              <g
                id="g81"
                style={{ filter: `url(#Adobe_OpacityMaskFilter_1_)` }}
              >
                <linearGradient
                  id="XMLID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="-1.3329999"
                  y1="38.064899"
                  x2="317"
                  y2="38.064899"
                  gradientTransform="matrix(1,0,0,-1,0,77.2959)"
                >
                  <stop
                    offset="0"
                    style={{ stopColor: `#000000` }}
                    id="stop52"
                  />
                  <stop
                    offset="0.0851"
                    style={{ stopColor: `#393939` }}
                    id="stop54"
                  />
                  <stop
                    offset="0.1984"
                    style={{ stopColor: `#7F7F7F` }}
                    id="stop56"
                  />
                  <stop
                    offset="0.3008"
                    style={{ stopColor: `#B6B6B6` }}
                    id="stop58"
                  />
                  <stop
                    offset="0.3883"
                    style={{ stopColor: `#DEDEDE` }}
                    id="stop60"
                  />
                  <stop
                    offset="0.4576"
                    style={{ stopColor: `#F6F6F6` }}
                    id="stop62"
                  />
                  <stop
                    offset="0.5"
                    style={{ stopColor: `#FFFFFF` }}
                    id="stop64"
                  />
                  <stop
                    offset="0.5424"
                    style={{ stopColor: `#F6F6F6` }}
                    id="stop66"
                  />
                  <stop
                    offset="0.6117"
                    style={{ stopColor: `#DEDEDE` }}
                    id="stop68"
                  />
                  <stop
                    offset="0.6992"
                    style={{ stopColor: `#B6B6B6` }}
                    id="stop70"
                  />
                  <stop
                    offset="0.8016"
                    style={{ stopColor: `#7F7F7F` }}
                    id="stop72"
                  />
                  <stop
                    offset="0.9149"
                    style={{ stopColor: `#393939` }}
                    id="stop74"
                  />
                  <stop
                    offset="1"
                    style={{ stopColor: `#000000` }}
                    id="stop76"
                  />
                </linearGradient>
                <rect
                  x="-1.3329999"
                  y="25.232"
                  width="318.33301"
                  height="27.999001"
                  id="rect79"
                  style={{
                    fill: `url(#XMLID_1_)`,
                    stroke: `none`
                  }}
                />
              </g>
            </mask>
            <g mask="url(#XMLID_7_)" id="g90">
              <path
                d="m 315.833,27.898 c 0,4.5 -63,4.5 -63,4.5 0,0 -94.5,0 -94.5,4.5 0,0 -2,0 -2,4.5 0,4.5 2,9 2,9 0,0 2,-4.5 2,-9 0,-4.5 -2,-4.5 -2,-4.5 0,-4.5 -94.5,-4.5 -94.5,-4.5 0,0 -63,0 -63,-4.5"
                id="path84"
                style={{ fill: `none`, stroke: `#ffd700` }}
              />
            </g>
          </g>
        </svg>
      </section>
    );
  }
}
