import "./LoaderSymbol.scss";
import React from "react";

const LoaderSymbol = () => {
  return (
    <div id="wsh-LoaderSymbol">
      {/*<svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        width="283.6"
        height="283.6"
        viewBox="0 0 283.6 283.6"
        overflow="visible"
      >
        <g
          id="g18"
          fill="none"
          stroke="#fff200"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="141.8" cy="141.8" r="136.8" id="circle16" />
          <g id="g14">
            <path
              d="m 175.067,93.429 c -20,-8 -26,-35 -26,-35 0,184.334 5.667,202.334 -7,202 -31.989,-0.844 11.695,-147.052 54.667,-141 23.667,3.333 16.333,65 -3.667,65 -20,0 -48,-38.333 -133,-28.333 20,-13 19,-16.667 19,-16.667"
              id="path2"
            />
            <path
              d="m 123.067,118.762 c -6,4.667 -9.666,12.667 -9.666,12.667 0,0 -27.334,-50 -57.334,-68.333"
              id="path4"
            />
            <path
              d="m 110.734,28.096 c 3.333,2.667 8.667,15.667 8.667,15.667"
              id="path6"
            />
            <path
              d="m 134.401,23.429 c 2,3.333 4.333,15.667 4.333,15.667"
              id="path8"
            />
            <path
              d="m 161.066,23.167 c 0.54,4.981 -1.664,15.524 -1.664,15.524"
              id="path10"
            />
            <path
              d="m 185.075,28.266 c -0.078,3.399 -4.348,13.659 -4.348,13.659"
              id="path12"
            />
          </g>
        </g>
      </svg>*/}
      {/*<svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        width="283.6"
        height="283.6"
        viewBox="0 0 283.6 283.6"
        overflow="visible"
      >
        <g
          id="g18"
          stroke="#fff200"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            cx="141.8"
            cy="141.8"
            r="136.8"
            id="circle16"
            fill="#d4b621"
          />
          <g id="g14" fill="none">
            <path
              d="m 175.067,93.429 c -20,-8 -26,-35 -26,-35 0,184.334 5.667,202.334 -7,202 -31.989,-0.844 11.695,-147.052 54.667,-141 23.667,3.333 16.333,65 -3.667,65 -20,0 -48,-38.333 -133,-28.333 20,-13 19,-16.667 19,-16.667"
              id="path2"
            />
            <path
              d="m 123.067,118.762 c -6,4.667 -9.666,12.667 -9.666,12.667 0,0 -27.334,-50 -57.334,-68.333"
              id="path4"
            />
            <path
              d="m 110.734,28.096 c 3.333,2.667 8.667,15.667 8.667,15.667"
              id="path6"
            />
            <path
              d="m 134.401,23.429 c 2,3.333 4.333,15.667 4.333,15.667"
              id="path8"
            />
            <path
              d="m 161.066,23.167 c 0.54,4.981 -1.664,15.524 -1.664,15.524"
              id="path10"
            />
            <path
              d="m 185.075,28.266 c -0.078,3.399 -4.348,13.659 -4.348,13.659"
              id="path12"
            />
          </g>
        </g>
      </svg>*/}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 283.6 283.6"
        overflow="visible"
      >
        <g
          id="g18"
          fill="none"
          stroke="gold"
          strokeWidth="8.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g id="g14">
            <circle
              cx="141.8"
              cy="141.8"
              r="136.8"
              id="circle16"
              fill="#1f233a"
            />
            <path
              d="m 175.067,93.429 c -20,-8 -26,-35 -26,-35 0,184.334 5.667,202.334 -7,202 -31.989,-0.844 11.695,-147.052 54.667,-141 23.667,3.333 16.333,65 -3.667,65 -20,0 -48,-38.333 -133,-28.333 20,-13 19,-16.667 19,-16.667"
              id="path2"
            />
            <path
              d="m 123.067,118.762 c -6,4.667 -9.666,12.667 -9.666,12.667 0,0 -27.334,-50 -57.334,-68.333"
              id="path4"
            />
            <path
              d="m 110.734,28.096 c 3.333,2.667 8.667,15.667 8.667,15.667"
              id="path6"
            />
            <path
              d="m 134.401,23.429 c 2,3.333 4.333,15.667 4.333,15.667"
              id="path8"
            />
            <path
              d="m 161.066,23.167 c 0.54,4.981 -1.664,15.524 -1.664,15.524"
              id="path10"
            />
            <path
              d="m 185.075,28.266 c -0.078,3.399 -4.348,13.659 -4.348,13.659"
              id="path12"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LoaderSymbol;
